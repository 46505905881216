import React from 'react'
import { DateTime } from 'luxon'

function DateTimeInput({ value, onChange }) {
  const onDateChange = event => {
    const newStringValue = event.target.value
    const [year, month, day] = newStringValue.split('-')

    try {
      const updatedDateTime = value.set({ year, month, day })
      onChange(updatedDateTime)
    } catch {}
  }

  const onTimeChange = event => {
    const newStringValue = event.target.value
    const [hour, minute] = newStringValue.split(':')

    try {
      const updatedDateTime = value.set({ hour, minute, second: 0, millisecond: 0 })
      onChange(updatedDateTime)
    } catch {}
  }

  return (
    <>
      <input
        type="date"
        value={value.toISODate()}
        onChange={onDateChange}
      />
      <input
        type="time"
        value={value.toLocaleString(DateTime.TIME_24_SIMPLE)}
        onChange={onTimeChange}
      />
    </>
  )
}

export default DateTimeInput
