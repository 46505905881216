import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { LabeledInputBlock, DateInput } from '../form'
import { Settings } from '../types'
import { dateOfTime } from '../utilities2'

function LowestVisibilityUntilInput(
  { onChange, value, settings }:
  {
    value: string;
    onChange: (newValue: string) => void;
    settings: Settings;
  },
) {
  const id = 'lowest-visibility-until'
  const minimumDate = useMemo(() => dateOfTime(DateTime.now().plus({ days: 1 }), settings), [settings])

  return (
    <LabeledInputBlock
      id={id}
      text="Lowest visbility until"
      description="Mute this project until the specified date"
      optional
    >
      <DateInput
        onChange={onChange}
        value={value}
        id={id}
        min={minimumDate}
      />
    </LabeledInputBlock>
  )
}

export default LowestVisibilityUntilInput
