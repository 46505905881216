import { useEffect, useRef, ReactNode } from 'react'
import { Image as ImageType } from './types'
import Image from './Image'
import './Backdrop.css'

function Layer({ children }: { children: ReactNode }) {
  return (
    <div className="BackdropLayer">
      {children}
    </div>
  )
}

function Backdrop(
  { backgroundColor, wallpaperImage, backgroundImages }:
  {
    backgroundColor: string;
    wallpaperImage?: ImageType;
    backgroundImages: ImageType[];
  },
) {
  const dynamicLayerDiv = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!dynamicLayerDiv.current) return

    dynamicLayerDiv.current.style.backgroundColor = backgroundColor
  }, [backgroundColor])

  return (
    <div className="Backdrop">
      <div ref={dynamicLayerDiv} className="Backdrop-dynamic-layer">
        {wallpaperImage && (
          <Layer>
            <Image image={wallpaperImage} />
          </Layer>
        )}
        {backgroundImages[0] && (
          <Layer>
            <Image image={backgroundImages[0]} />
          </Layer>
        )}
      </div>
      <div className="Backdrop-gradient-layer">
        <div className="Backdrop-gradient-layer-block" />
        <div className="Backdrop-gradient-layer-gradient" />
      </div>
    </div>
  )
}

export default Backdrop
