import { useMemo, useState } from 'react'
import { ImagesIndex, Id, Image as ImageType } from './types'
import useProjectImages from './hooks/useProjectImages'
import useImageProjectLinkers from './hooks/useImageProjectLinkers'
import NewImageForm from './NewImageForm'
import { FaLink, FaLinkSlash } from 'react-icons/fa6'
import './AddImageToProject.css'

function LinkImageButton({ image, projectId, isLinked }: { image: ImageType; projectId: Id; isLinked: boolean; }) {
  const { linkImageToProject, unlinkImageFromProject } = useImageProjectLinkers({ projectId, imageId: image.id })
  const [buttonHovered, setButtonHovered] = useState(false)

  return (
    <div
      className="AddImageToProject-LinkImageButton"
      onClick={isLinked ? unlinkImageFromProject : linkImageToProject}
    >
      <div className="AddImageToProject-LinkImageButton-image-container">
        <img src={image.urlXs} alt="" />
      </div>
      {isLinked && (
        <div
          className="AddImageToProject-LinkImageButton-checkmark-overlay"
          onMouseEnter={() => setButtonHovered(true)}
          onMouseLeave={() => setButtonHovered(false)}
        >
          {buttonHovered ? <FaLinkSlash /> : <FaLink />}
        </div>
      )}
    </div>
  )
}

function AddImageToProject({ imagesIndex, projectId }: { imagesIndex: ImagesIndex; projectId: Id; }) {
  const projectImages = useProjectImages({ imagesIndex, projectId })
  const projectImagesIds = useMemo(() => projectImages.map((image) => image.id), [projectImages])
  const images = useMemo(() => {
    return Object.keys(imagesIndex)
      .map((key) => imagesIndex[key])
  }, [imagesIndex])

  return (
    <div className="AddImageToProject">
      <div className="AddImageToProject-upload-form-container">
        <h3>Upload a new image</h3>
        <div>
          <NewImageForm projectId={projectId} />
        </div>
      </div>
      {images.length > 0 && (
        <div>
          <h3>Link an existing image to this project</h3>
          <div className="AddImageToProject-images-container">
            {images.map((image) => (
              <LinkImageButton key={image.id} image={image} projectId={projectId} isLinked={projectImagesIds.includes(image.id)} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default AddImageToProject
