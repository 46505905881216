import { LabeledInputBlock, TimeInput } from '../form'
import { TimeWindow } from '../types'

function WorkingHoursInput(
  { onChange, value }:
  {
    onChange: (timeWindow: TimeWindow) => void;
    value: TimeWindow;
  },
) {
  return (
    <LabeledInputBlock
      id="project-working-hours"
      text="Working hours"
      description="The time frame during which you are usually looking to work in."
      optional={false}
    >
      <div className="Settings-working-hours-input-container">
        <div>
          <TimeInput
            value={value.from}
            onChange={(newValue) => onChange({ ...value, from: newValue })}
            required
          />
        </div>
        <div>
          -
        </div>
        <div>
          <TimeInput
            value={value.to}
            onChange={(newValue) => onChange({ ...value, to: newValue })}
            required
          />
        </div>
      </div>
    </LabeledInputBlock>
  )
}

export default WorkingHoursInput
