import React from 'react'
import theme from '../theme'

function Label({ htmlFor, children, description, optional }) {
  return (
    <label htmlFor={htmlFor}>
      {children}
      {optional && (
        <span style={styles.optional}> (Optional)</span>
      )}
      {description && (
        <div style={styles.description}>{description}</div>
      )}
    </label>
  )
}

const styles = {
  description: {
    fontSize: '75%',
    color: theme.colors.gray,
  },
  optional: {
    fontSize: '60%',
    color: theme.colors.primary,
  },
}

export default Label
