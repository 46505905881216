import HtmlButton from '../clickables/HtmlButton'
import classNames from 'classnames'

const classNamesForTheme = (baseName: string, theme: string, disabled?: boolean) => {
  return classNames(
    baseName,
    `${baseName}-${theme}`,
    {
      [`${baseName}-disabled`]: disabled === true,
    },
  )
}

const Button = (
  { children, theme, onClick, title, disabled }:
  {
    children: React.ReactNode;
    theme: 'purple-pressed' | 'ice';
    onClick: () => void;
    title: string;
    disabled?: boolean;
  },
) => {
  return (
    <HtmlButton
      onClick={onClick}
      title={title}
      className={classNamesForTheme('SessionSectionButton', theme, disabled)}
      disabled={disabled}
      submit={false}
    >
      {children}
    </HtmlButton>
  )
}

const IconButton = (
  { icon, text, theme, disabled, onClick, title }:
  {
    onClick: () => void;
    title: string;
    icon: React.ReactNode;
    text?: string;
    theme: 'purple-pressed' | 'ice';
    disabled?: boolean;
  },
) => {
  return (
    <Button theme={theme} disabled={disabled} title={title} onClick={onClick}>
      <div
        className={classNamesForTheme('IconButton-icon-row', theme, disabled)}
      >
        {icon}
      </div>
      {text && (
        <div
          className={classNamesForTheme('IconButton-text-row', theme, disabled)}
        >
          {text}
        </div>
      )}
    </Button>
  )
}

export default IconButton
