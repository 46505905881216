import { DateTime } from 'luxon'
import { Settings, Project, Streak, TimeWindow, RepeatCycle } from './types'

export const determineIsTimeOff = (settings: Settings, project: Project) => {
  const now = DateTime.local()

  if (project.daysOff && isDayOff(now, project.daysOff, settings)) return true
  if (isTimeOff(now, project.workingHours)) return true

  return false
}

export const isTimeOff = (dateTime: DateTime, workingHours: TimeWindow) => {
  const localDateTime = dateTime.toLocal()
  const localHM = localDateTime.toLocaleString(DateTime.TIME_24_SIMPLE)

  if (workingHours.from === workingHours.to) {
    return false
  } else if (workingHours.from < workingHours.to) {
    if (localHM < workingHours.from || localHM > workingHours.to) return true
  } else {
    if (localHM > workingHours.to && localHM < workingHours.from) return true
  }

  return false
}

export const isDayOff = (dateTime: DateTime, daysOff: RepeatCycle, settings: Settings) => {
  if (!daysOff.weekly) return false

  const localDateTime = dateTime.toLocal()
  const localHM = localDateTime.toLocaleString(DateTime.TIME_24_SIMPLE)
  const weekday = localHM < settings.timeOfDaybreak ? localDateTime.weekday - 1 : localDateTime.weekday

  switch (weekday) {
  case 1:
    return daysOff.weekly.selectedWeekdays.monday
  case 2:
    return daysOff.weekly.selectedWeekdays.tuesday
  case 3:
    return daysOff.weekly.selectedWeekdays.wednesday
  case 4:
    return daysOff.weekly.selectedWeekdays.thursday
  case 5:
    return daysOff.weekly.selectedWeekdays.friday
  case 6:
    return daysOff.weekly.selectedWeekdays.saturday
  case 7:
    return daysOff.weekly.selectedWeekdays.sunday
  }
}

export const calculateProjectNeglectionFactor = ({ project, streak }: { project: Project; streak: Streak; }) => {
  if (!project.frequency || !streak || !streak.numberOfDaysSinceLastSession) return null

  return streak.numberOfDaysSinceLastSession / project.frequency
}

export const localStartTimeOfDate = (date: string, timeOfDaybreak: string) => {
  return DateTime.fromISO(`${date}T${timeOfDaybreak}`, { zone: 'local' })
}

export const dateIsUpcoming = (date: string, now: DateTime, timeOfDaybreak: string) => {
  return now < localStartTimeOfDate(date, timeOfDaybreak)
}

export const dateOfTime = (now: DateTime, settings: Settings) => {
  const [offsetHours, offsetMinutes] = settings.timeOfDaybreak.split(':').map((stringValue) => parseInt(stringValue))
  return now.toLocal().minus({ hours: offsetHours, minutes: offsetMinutes }).toISODate()
}
