import React from 'react'

function Checkbox({ checkboxRef, checked, onChange, id }) {
  return (
    <input
      type="checkbox"
      ref={checkboxRef}
      checked={checked}
      onChange={(newValue) => onChange(newValue.currentTarget.checked)}
      id={id}
    />
  )
}

export default Checkbox
