import { useMutation } from '@apollo/client'
import { UPDATE_PROJECT_MUTATION } from './queries'
import { buildUpdateProjectParams, updateProjectCache } from './utilities'
import { dateOfTime } from './utilities2'
import { DateTime } from 'luxon'
import { FaXmark, FaEye, FaEyeSlash, FaMoon } from 'react-icons/fa6'
import { ProjectSelectorProject, Settings } from './types'
import './ProjectQuickMenu.css'

function ProjectQuickMenu(
  { project, position, onActionCompleted, onClickClose, settings }:
  {
    project: ProjectSelectorProject,
    position: { x: number, y: number },
    onActionCompleted: () => void,
    onClickClose: () => void,
    settings: Settings,
  },
) {
  const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION, {
    update(cache, data) { updateProjectCache(cache, data) },
  })

  const setFocus = async(newFocus: boolean) => {
    const variables = buildUpdateProjectParams(project, {
      focus: newFocus,
    })

    await updateProject({ variables })

    if (onActionCompleted) onActionCompleted()
  }

  const setSnoozeToday = async() => {
    const variables = buildUpdateProjectParams(project, {
      lowestVisibilityUntil: dateOfTime(DateTime.now().plus({ hours: 24 }), settings),
    })

    await updateProject({ variables })

    if (onActionCompleted) onActionCompleted()
  }

  const unsnooze = async() => {
    const variables = buildUpdateProjectParams(project, {
      lowestVisibilityUntil: null,
    })

    await updateProject({ variables })

    if (onActionCompleted) onActionCompleted()
  }

  return (
    <div className="ProjectQuickMenu" style={{ top: position.y, left: position.x }}>
      <div className="ProjectQuickMenu-header">
        <div className="ProjectQuickMenu-header-name">
          {project.name}
        </div>
        <div>
          <button onClick={onClickClose}><FaXmark /></button>
        </div>
      </div>
      <ul>
        <li>
          {project.focus ? (
            <button onClick={() => setFocus(false)}>
              <span className="ProjectQuickMenu-icon"><FaEyeSlash /></span> Unfocus
            </button>
          ) : (
            <button onClick={() => setFocus(true)}>
              <span className="ProjectQuickMenu-icon"><FaEye /></span> Focus
            </button>
          )}
        </li>
        <li>
          {project.isSnoozed ? (
            <button onClick={() => unsnooze()}>
              <span className="ProjectQuickMenu-icon"><FaMoon /></span> Unsnooze
            </button>
          ) : (
            <button onClick={() => setSnoozeToday()}>
              <span className="ProjectQuickMenu-icon"><FaMoon /></span> Snooze for today
            </button>
          )}
        </li>
      </ul>
    </div>
  )
}

export default ProjectQuickMenu
