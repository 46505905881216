import { useMemo } from 'react'
import { GetImageIdsResult, Id, ImagesIndex } from '../types'
import { useQuery } from '@apollo/client'
import { FETCH_IMAGE_IDS_FOR_PROJECT_QUERY } from '../queries'

function useProjectImages({ projectId, imagesIndex }: { projectId?: Id; imagesIndex: ImagesIndex }) {
  const { data: imageIdsData } = useQuery<GetImageIdsResult>(FETCH_IMAGE_IDS_FOR_PROJECT_QUERY, {
    variables: { projectId: projectId },
    skip: !projectId,
  })

  const images = useMemo(() => {
    if (!imageIdsData || !imageIdsData.getImages) return []

    return imageIdsData.getImages
      .map(image => imagesIndex[image.id])
      .filter(element => element != null)
  }, [imagesIndex, imageIdsData])

  return images
}

export default useProjectImages
