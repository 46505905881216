import { useState, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { updateProjectCache, buildUpdateProjectParams } from './utilities'
import { UPDATE_PROJECT_MUTATION } from './queries'
import { MultilineTextInput } from './form'
import SectionContainer from './SectionContainer'
import { Project } from './types'
import useInputFieldAutoUpdate from './hooks/useInputFieldAutoUpdate'

// NOTE: only use this component with a `key`.
function ProjectNotesEditor({ project }: { project: Project }) {
  const [notes, setNotes] = useState(project.notes)
  const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION, {
    update(cache, data) { updateProjectCache(cache, data) },
  })
  const updateProjectMutationVariablesForNewNotes = useCallback((newNotes) => {
    return buildUpdateProjectParams(project, { notes: newNotes })
  }, [project])
  const { outOfSync } = useInputFieldAutoUpdate({
    inputFieldValue: notes,
    storedValueAsInputFieldValue: project.notes,
    mutation: updateProject,
    buildMutationVariablesWithNewValue: updateProjectMutationVariablesForNewNotes,
  })

  return (
    <SectionContainer fullWidth fullHeight>
      <MultilineTextInput
        onChange={(newValue) => setNotes(newValue)}
        value={notes}
        placeholder="Project notes"
        outOfSync={outOfSync}
        fullWidth
        fullHeight
        disableResizing
      />
    </SectionContainer>
  )
}

export default ProjectNotesEditor
