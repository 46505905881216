import { useState, useEffect, useRef } from 'react'
import { useQuery } from '@apollo/client'
import Todos from './Todos'
import Stopwatch from './Stopwatch'
import ActivitySummary from './ActivitySummary'
import Sessions from './Sessions'
import ProjectNotesEditor from './ProjectNotesEditor'
import Footer from './Footer'
import ActivityStatistics from './ActivityStatistics'
import SessionsCollection from './SessionsCollection'
import { FETCH_SESSIONS_QUERY } from './queries'
import { RELATIVE_ACTIVITY_INTERVAL } from './utilities'
import ColorPalette from './ColorPalette'
import SectionContainer from './SectionContainer'
import ImageBrowser from './ImageBrowser'
import classNames from 'classnames'
import './Dashboard.css'

function Dashboard({
  appState,
  projectsDict,
  selectedProject,
  setSelectedProject,
  openModal,
  updateAppState,
  updateAppStateNotes,
  sessionNotesOutOfSync,
  updateAppStateSummary,
  sessionSummaryOutOfSync,
  activityStatistics,
  setActivityStatistics,
  addFlashMessage,
  backgroundImageId,
  setBackgroundImageId,
  isMuted,
  setIsMuted,
  imagesIndex,
  settings,
  isTimeOff,
}) {
  const [sessionsCollection, setSessionsCollection] = useState(null)
  const { data: sessionsData } = useQuery(FETCH_SESSIONS_QUERY)
  const hidePanelDiv = useRef()

  // Keep the selected project up-to-date.
  useEffect(() => {
    if (!projectsDict) return

    const selectedProjectId = appState ? appState.projectId : null
    setSelectedProject(projectsDict[selectedProjectId])
  }, [projectsDict, appState, setSelectedProject])

  // Process sessions.
  useEffect(() => {
    if (!sessionsData || !settings) return

    const t0 = performance.now()

    const { sessions } = sessionsData
    const newActivityStatistics = new ActivityStatistics({
      streakTolerance: 0.1,
      settings,
      relativeActivityInterval: RELATIVE_ACTIVITY_INTERVAL,
    })
    const newSessionsCollection = new SessionsCollection(sessions)
    sessions.forEach(session => newActivityStatistics.addSession(session))

    const t1 = performance.now()
    console.log(`Processed ${sessions.length} sessions after ${t1 - t0} ms`)

    setSessionsCollection(newSessionsCollection)
    setActivityStatistics(newActivityStatistics)
  }, [sessionsData, settings, setActivityStatistics])

  const onColumnClick = (event) => {
    if (event.target !== hidePanelDiv.current || isMuted || !(backgroundImageId || selectedProject.wallpaperImageId)) {
      return
    }

    setIsMuted(true)
  }

  return (
    <div className="App-content">
      <div ref={hidePanelDiv} className="App-3-cols" onMouseDown={onColumnClick}>
        <div className="App-3-cols-col-container">
          <div className={classNames('App-3-cols-col-content', 'Dashboard-todos-container', { 'Dashboard-panel-faded': isMuted })}>
            {appState && appState.projectId && (
              <Todos appState={appState} />
            )}
          </div>
        </div>
        <div className="App-3-cols-col-container App-stopwatch-container">
          <div className="App-3-cols-col-content App-3-cols-col-content-stopwatch">
            <div className={classNames('Dashboard-middle-panel-stopwatch', { 'Dashboard-panel-faded': isMuted })}>
              <div className="Dashboard-middle-panel-stopwatch2">
                <Stopwatch
                  appState={appState}
                  updateAppState={updateAppState}
                  addFlashMessage={addFlashMessage}
                  updateAppStateNotes={updateAppStateNotes}
                  sessionNotesOutOfSync={sessionNotesOutOfSync}
                  updateAppStateSummary={updateAppStateSummary}
                  sessionSummaryOutOfSync={sessionSummaryOutOfSync}
                  isTimeOff={isTimeOff}
                />
              </div>
            </div>
            <div className="Dashboard-middle-panel-moodboard">
              <SectionContainer widthByContent>
                <ImageBrowser
                  backgroundImageId={backgroundImageId}
                  setBackgroundImageId={setBackgroundImageId}
                  dashboardMuted={isMuted}
                  setDashboardMuted={setIsMuted}
                  projectId={appState.projectId}
                  imagesIndex={imagesIndex}
                  openModal={openModal}
                />
              </SectionContainer>
            </div>
          </div>
        </div>
        <div className="App-3-cols-col-container App-project-container">
          <div className="App-3-cols-col-content">
            {selectedProject && (
              <div className={classNames('Dashboard-project-container', { 'Dashboard-panel-faded': isMuted })}>
                <div className="App-project-introduction">
                  <div className="App-project-introduction-container">
                    <div className="App-project-introduction-name">
                      <h1>{selectedProject.name}</h1>
                    </div>
                    <div className="App-project-introduction-description"><p>{selectedProject.description}</p></div>
                    {selectedProject.minutesPerDay && (
                      <div className="App-project-introduction-minutesPerDay">
                        {selectedProject.minutesPerDay} min / day
                      </div>
                    )}
                  </div>
                  {selectedProject.colorPalette.length > 0 && (
                    <div style={{ marginLeft: 13, marginTop: 17 }}>
                      <ColorPalette colorPalette={selectedProject.colorPalette} />
                    </div>
                  )}
                </div>
                <div className="App-project-notes">
                  <ProjectNotesEditor
                    key={selectedProject.id} // ensures that the component gets remounted when the project changes
                    project={selectedProject}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={classNames('App-tail', { 'Dashboard-panel-faded': isMuted })}>
        <div className="App-sessions-row">
          <Sessions projectsDict={projectsDict} appState={appState} sessionsCollection={sessionsCollection} />
        </div>
        <div className="App-daily-activity-container">
          <ActivitySummary
            selectedProject={selectedProject}
            activityStatistics={activityStatistics}
          />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Dashboard
