import React, { useEffect } from 'react'
import closeSvg from './close.svg'
import './Modal.css'

function Modal({ headline, closeModal, children }) {
  useEffect(() => {
    const keyDown = event => {
      if(event.key === 'Escape') {
        closeModal()
      }
    }

    document.addEventListener('keydown', keyDown)

    return () => {
      document.removeEventListener('keydown', keyDown)
    }
  }, [closeModal])

  return (
    <div className="Modal-backdrop">
      <div className="Modal-container">
        <div className="Modal-header-container">
          <div className="Modal-header-headline-container">
            <h1>{headline}</h1>
          </div>
          <div className="Modal-header-close-container">
            <img
              src={closeSvg}
              onClick={closeModal}
              alt="Close modal"
            />
          </div>
        </div>
        <div className="Modal-body">
          {children}
        </div>
      </div>
    </div>
  )
};

export default Modal
