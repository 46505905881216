import React from 'react'

import './Link.css'

function Link({ label, href, openInNewTab, styles = [] }) {
  const classes = ['Link']

  styles.forEach(style => {
    classes.push(`Link-${style}`)
  })

  const conditionalParams = {}

  if (openInNewTab) {
    conditionalParams['target'] = '_blank'
    conditionalParams['rel'] = 'noopener noreferrer'
  }

  return (
    <a
      href={href}
      className={classes.join(' ')}
      {...conditionalParams}
    >
      {label}
    </a>
  )
}

export default Link
