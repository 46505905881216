import { DateTime } from 'luxon'
import { sessionDuration } from './utilities'

class SessionsCollection {
  constructor(orderedRawSessions) {
    this.orderedRawSessions = orderedRawSessions
    this.sessionsByProject = null
  }

  _groupByProject() {
    const t0 = performance.now()
    const groupedSessions = {}

    for (const session of this.orderedRawSessions) {
      if (!groupedSessions[session.projectId]) {
        groupedSessions[session.projectId] = []
      }

      groupedSessions[session.projectId].push(session)
    }

    const t1 = performance.now()
    console.log(`Grouping ${this.orderedRawSessions.length} sessions completed after ${t1 - t0} ms`)

    return groupedSessions
  }

  _sessionsForProject(projectId) {
    if (!this.sessionsByProject) {
      this.sessionsByProject = this._groupByProject()
    }

    this.sessionsByProject[projectId] = this.orderedRawSessions.filter(session => session.projectId === projectId)

    return this.sessionsByProject[projectId]
  }

  forProject(projectId) {
    const newOrderedRawSessions = this._sessionsForProject(projectId)

    return new SessionsCollection(newOrderedRawSessions)
  }

  limit(amount) {
    const newOrderedRawSessions = this.orderedRawSessions.slice(0, amount)

    return new SessionsCollection(newOrderedRawSessions)
  }

  count() {
    return this.orderedRawSessions.length
  }

  toArray() {
    return this.orderedRawSessions.concat().map(session => {
      const startedAt = DateTime.fromISO(session.startedAt)
      const endedAt = DateTime.fromISO(session.endedAt)
      const duration = sessionDuration(session)

      return {
        ...session,
        startedAt,
        endedAt,
        duration,
      }
    })
  }
}

export default SessionsCollection
