import React from 'react'

function Errors({ messages }) {
  if (!messages || messages.length === 0) return null

  // Only display distinct messages.
  const uniqueMessages = []
  messages.forEach(message => {
    if(!uniqueMessages.includes(message)) {
      uniqueMessages.push(message)
    }
  })

  return (
    <div>
      {messages.map(message => (
        <div key={message}>{message}</div>
      ))}
    </div>
  )
}

export default Errors
