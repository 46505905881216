import React from 'react'
import { Label } from '../form'

function LabeledInputBlock({ id, text, description, optional, children }) {
  return (
    <>
      <div style={styles.container}>
        <Label
          htmlFor={id}
          description={description}
          optional={optional}
        >
          {text}
        </Label>
      </div>
      {children}
    </>
  )
}

const styles = {
  container: {
    paddingBottom: 7,
  },
}

export default LabeledInputBlock
