import React, { useEffect, useRef } from 'react'
import { DateTime } from 'luxon'
import Chart from 'chart.js/auto'

function DailyActivity({ activityStatistics, selectedProject }) {
  const activityChart = useRef(null)

  useEffect(() => {
    const dateCodeOfLastDay = activityStatistics.dateCode(DateTime.local())
    const canvas = activityChart.current
    const myChartRef = canvas.getContext('2d')
    const dailyActivityTotal = activityStatistics.dailyActivityTotal(dateCodeOfLastDay, 28)
    const datasets = [
      {
        label: 'Total activity (minutes)',
        data: dailyActivityTotal.map(([label, value]) => [label, Math.round(value)]),
        backgroundColor: 'rgba(50, 50, 50, 50%)',
        pointBackgroundColor: 'rgba(10, 10, 10, 100%)',
        borderColor: 'rgba(50, 50, 50, 80%)',
        borderWidth: 1,
        pointBorderWidth: 2,
        pointRadius: 4,
        pointBorderColor: 'rgba(50, 50, 50, 80%)',
        fill: true,
      },
    ]

    if (selectedProject) {
      const dailyActivityCurrentProject = activityStatistics.dailyActivityProject(selectedProject.id, dateCodeOfLastDay, 28)
      datasets.unshift({
        label: `Activity for ${selectedProject.name} (minutes)`,
        data: dailyActivityCurrentProject.map(([label, value]) => [label, Math.round(value)]),
        backgroundColor: 'rgba(116, 0, 255, 90%)',
        pointRadius: 4,
        fill: true,
      })
    }

    const chart = new Chart(myChartRef, {
      type: 'line',
      data: {
        labels: dailyActivityTotal.map(activity => activity[0]),
        datasets,
      },
      options: {
        scales: {
          y: {
            ticks: {
              display: false,
            },
          },
          x: {
            ticks: {
              display: false,
            },
          },
        },
        aspectRatio: 7,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    })

    return () => {
      chart.destroy()
      myChartRef.clearRect(0, 0, canvas.width, canvas.height)
    }
  }, [activityStatistics, selectedProject])

  return (
    <canvas ref={activityChart} />
  )
}

export default DailyActivity
