import { useState, useEffect, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { DateTime } from 'luxon'
import DateTimeInput from './DateTimeInput'
import { Button } from './clickables'
import {
  FETCH_SESSIONS_QUERY,
  DELETE_SESSION_QUERY,
  UPDATE_SESSION_MUTATION,
  FETCH_PROJECTS_QUERY,
} from './queries'
import './Sessions.css'
import './SessionRow.css'
import styles from './Sessions.module.scss'

const defaultLimit = 5

function ProjectsSelect({ projectsDict, includeOptionAll, onChange, value }) {
  const selectProjectOptions = useMemo(() => {
    if (!projectsDict) return null

    return Object.values(projectsDict)
      .map((project) => ({ value: project.id, label: project.name }))
      .sort((a, b) => {
        const textA = a.label.toUpperCase()
        const textB = b.label.toUpperCase()
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
      })
  }, [projectsDict])

  return (
    <select
      value={value}
      onChange={event => onChange(event.currentTarget.value)}
    >
      {includeOptionAll && (
        <option value="">All</option>
      )}
      {selectProjectOptions && selectProjectOptions.map((selectProjectOption) => (
        <option key={selectProjectOption.value} value={selectProjectOption.value}>{selectProjectOption.label}</option>
      ))}
    </select>
  )
}

function SessionRow({ session, projectsDict }) {
  const [editedSession, setEditedSession] = useState(session)
  const [isEditing, setIsEditing] = useState(false)
  const project = session.projectId && projectsDict ? projectsDict[session.projectId] : null

  const [deleteSession] = useMutation(DELETE_SESSION_QUERY, {
    update(cache, { data: { deleteSession } }) {
      if (!deleteSession) return

      // Remove the session from the array of loaded sessions.
      const { sessions } = cache.readQuery({ query: FETCH_SESSIONS_QUERY })

      cache.writeQuery({
        query: FETCH_SESSIONS_QUERY,
        data: { sessions: sessions.filter(session => session.id !== deleteSession) },
      })
    },
    refetchQueries: [
      {
        query: FETCH_PROJECTS_QUERY,
        variables: {},
      },
    ],
  })

  const [updateSession] = useMutation(UPDATE_SESSION_MUTATION, {
    update(cache, { data: { updateSession } }) {
      if (!updateSession) return console.log('Wait, what!? 1u3h8932rn')

      const { sessions } = cache.readQuery({ query: FETCH_SESSIONS_QUERY })
      const updatedSessions = [...sessions]
      const sessionIndex = updatedSessions.findIndex(updatedSession => updatedSession.id === session.id)
      updatedSessions[sessionIndex] = updateSession

      cache.writeQuery({
        query: FETCH_SESSIONS_QUERY,
        data: { sessions: updatedSessions },
      })

      setIsEditing(false)
    },
    refetchQueries: [
      {
        query: FETCH_PROJECTS_QUERY,
        variables: {},
      },
    ],
  })

  const clickDeleteSession = () => {
    const choiceConfirmed = window.confirm(`Do you really want to delete session "${session.summary}"?`)

    if (!choiceConfirmed) return

    deleteSession({ variables: { id: session.id } })
  }

  const clickSaveChanges = () => {
    const updatedSessionVariables = {
      ...editedSession,
      startedAt: editedSession.startedAt.toISO(),
      endedAt: editedSession.endedAt.toISO(),
    }

    updateSession({ variables: updatedSessionVariables })
  }

  return (
    <div className="SessionRow">
      {isEditing ? (
        <>
          <div className="SessionRow-section-content">
            <div className="SessionRow-dates-form">
              <div>
                <DateTimeInput
                  value={editedSession.endedAt}
                  onChange={endedAt => setEditedSession({ ...editedSession, endedAt })}
                />
              </div>
              <div>
                <DateTimeInput
                  value={editedSession.startedAt}
                  onChange={startedAt => setEditedSession({ ...editedSession, startedAt })}
                />
              </div>
              <div>
                <ProjectsSelect
                  value={editedSession.projectId === null ? '' : editedSession.projectId}
                  onChange={value => setEditedSession({ ...editedSession, projectId: value === '' ? null : value })}
                  projectsDict={projectsDict}
                />
              </div>
            </div>
            <div className="SessionRow-description">
              <div className="SessionRow-summary">
                <input
                  value={editedSession.summary}
                  onChange={event => setEditedSession({ ...editedSession, summary: event.target.value })}
                  className="SessionRow-description-input"
                />
              </div>
              <div className="SessionRow-notes">
                <textarea
                  value={editedSession.notes}
                  onChange={event => setEditedSession({ ...editedSession, notes: event.target.value })}
                  className="SessionRow-notes-input"
                />
              </div>
            </div>
          </div>
          <div className="SessionRow-section-cta">
            <Button onClick={() => clickSaveChanges(session)}>Save changes</Button>
            <Button onClick={() => setIsEditing(false)}>Cancel</Button>
            <Button onClick={() => clickDeleteSession(session)}>Delete</Button>
          </div>
        </>
      ) : (
        <>
          <div className="SessionRow-section-content">
            <div className="SessionRow-date">{session.startedAt.toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}</div>
            <div className="SessionRow-time">
              <div className="SessionRow-time-ended">{session.endedAt.toLocaleString(DateTime.TIME_24_SIMPLE)}</div>
              <div className="SessionRow-time-started">{session.startedAt.toLocaleString(DateTime.TIME_24_SIMPLE)}</div>
            </div>
            <div className="SessionRow-duration">{Math.round(session.duration / 1000 / 60)} min</div>
            <div className="SessionRow-project">{project ? project.name : '-'}</div>
            <div className="SessionRow-description">
              <div className="SessionRow-summary">{session.summary}</div>
              <div className="SessionRow-notes">{session.notes}</div>
            </div>
          </div>
          <div className="SessionRow-section-cta">
            <Button onClick={() => setIsEditing(true)}>Edit</Button>
          </div>
        </>
      )}
    </div>
  )
}

function Sessions({ projectsDict, appState, sessionsCollection }) {
  const [sessions, setSessions] = useState([])
  const [projectIdFilter, setProjectIdFilter] = useState(null)
  const [selectedProjectId, setSelectedProjectId] = useState(null)
  const [limit, setLimit] = useState(defaultLimit)
  const [sessionsCount, setSessionsCount] = useState(0)

  useEffect(() => {
    if (!sessionsCollection) return

    let newSessionsCollection = sessionsCollection

    if (projectIdFilter) {
      newSessionsCollection = newSessionsCollection.forProject(projectIdFilter)
    }

    setSessionsCount(newSessionsCollection.count())
    setSessions(newSessionsCollection.limit(limit).toArray())
  }, [sessionsCollection, projectIdFilter, limit])

  useEffect(() => {
    if (!appState) return

    if (appState.projectId !== selectedProjectId) {
      setSelectedProjectId(appState.projectId)
      setProjectIdFilter(appState.projectId)
    }
  }, [appState, projectIdFilter, selectedProjectId])

  useEffect(() => {
    setLimit(defaultLimit)
  }, [projectIdFilter])

  return (
    <div className="Sessions">
      <div>
        <ProjectsSelect
          value={projectIdFilter === null ? '' : projectIdFilter}
          onChange={value => value === '' ? setProjectIdFilter(null) : setProjectIdFilter(value)}
          includeOptionAll
          projectsDict={projectsDict}
        />
      </div>
      {projectsDict && sessions.map(session => (
        <SessionRow key={session.id} session={session} projectsDict={projectsDict} />
      ))}
      {sessionsCount > limit && (
        <div className={styles.footer}>
          <Button onClick={() => setLimit(limit + defaultLimit)}>Show more</Button>
        </div>
      )}
    </div>
  )
}

export default Sessions
