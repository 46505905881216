import React from 'react'

function ActionRow({ children }) {
  return (
    <div style={styles.container}>
      {children}
    </div>
  )
}

const styles = {
  container: {
    marginTop: 25,
  },
}

export default ActionRow
