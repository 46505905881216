import React from 'react'
import { LabeledInputBlock, TextInput } from '../form'

function DescriptionInput({ inputRef, onChange, value }) {
  const id = 'project-description'

  return (
    <LabeledInputBlock
      id={id}
      text="Description"
      optional
    >
      <TextInput
        inputRef={inputRef}
        onChange={onChange}
        value={value}
        id={id}
      />
    </LabeledInputBlock>
  )
}

export default DescriptionInput
