import React from 'react'
import { LabeledInputBlock, TextInput } from '../form'

function NameInput({ inputRef, onChange, value }) {
  const id = 'project-name'

  return (
    <LabeledInputBlock
      id={id}
      text="Name"
    >
      <TextInput
        inputRef={inputRef}
        onChange={onChange}
        value={value}
        id={id}
        required
      />
    </LabeledInputBlock>
  )
}

export default NameInput
