import React from 'react'

function HtmlTextArea(
  { value, onChange, inputElementRef, id, className, rows, placeholder }:
  {
    value: string;
    onChange: (event: React.FormEvent<HTMLTextAreaElement>) => void;
    inputElementRef?: React.RefObject<HTMLTextAreaElement>;
    id?: string;
    className?: string;
    rows?: number;
    placeholder?: string;
  },
) {
  return (
    <textarea
      value={value}
      onChange={onChange}
      ref={inputElementRef}
      className={className}
      id={id}
      rows={rows}
      placeholder={placeholder}
    />
  )
}

export default HtmlTextArea
