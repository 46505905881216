import { useState, FormEvent } from 'react'
import {
  LabeledInputBlock,
  TimeInput,
  LabeledCheckbox,
  Form,
  InputGroup,
  ActionRow,
  NumberInput,
} from './form'
import { Button } from './clickables'
import { useMutation } from '@apollo/client'
import { UPDATE_SETTINGS_MUTATION, APP_STATE_QUERY } from './queries'
import { Settings, WeekdaySelection, GetAppStateResult, UpdateSettingsResult } from './types'
import './SettingsForm.css'

function SettingsForm(
  { settings, closeModal }:
  {
    settings: Settings;
    closeModal: () => void;
  },
) {
  const [workingHoursFrom, setWorkingHoursFrom] = useState(settings.workingHours.from)
  const [workingHoursTo, setWorkingHoursTo] = useState(settings.workingHours.to)
  const [minutesPerDay, setMinutesPerDay] = useState(settings.minutesPerDay === null ? '' : settings.minutesPerDay.toString())
  const [weekdays, setWeekdays] = useState(() => {
    if (!settings.daysOff || !settings.daysOff.weekly) {
      return {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      }
    }

    return settings.daysOff.weekly.selectedWeekdays
  })
  const [updateSettings, { loading: updatingSettings }] = useMutation<UpdateSettingsResult>(
    UPDATE_SETTINGS_MUTATION,
    {
      update(cache, { data: mutationData }) {
        if (!mutationData || !mutationData.updateSettings.settings) return

        const appStateResult = cache.readQuery<GetAppStateResult>({ query: APP_STATE_QUERY })

        if (!appStateResult) return

        cache.writeQuery<GetAppStateResult>({
          query: APP_STATE_QUERY,
          data: {
            ...appStateResult,
            getSettings: mutationData.updateSettings.settings,
          },
        })
      },
      onCompleted({ updateSettings: _newSettings }) {
        closeModal()
      },
    },
  )
  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    updateSettings({
      variables: {
        newSettings: {
          minutesPerDay: parseInt(minutesPerDay) || null,
          workingHours: {
            from: workingHoursFrom,
            to: workingHoursTo,
          },
          daysOff: {
            weekly: {
              selectedWeekdays: {
                monday: weekdays.monday,
                tuesday: weekdays.tuesday,
                wednesday: weekdays.wednesday,
                thursday: weekdays.thursday,
                friday: weekdays.friday,
                saturday: weekdays.saturday,
                sunday: weekdays.sunday,
              },
            },
          },
        },
      },
    })
  }


  return (
    <Form onSubmit={onSubmit}>
      <InputGroup>
        <LabeledInputBlock
          id="Settings-minutes-per-day"
          text="Minutes per day"
          description="The amount of minutes you aim to work each day"
          optional
        >
          <NumberInput
            onChange={setMinutesPerDay}
            value={minutesPerDay}
            id="Settings-minutes-per-day"
            min="1"
          />
        </LabeledInputBlock>
      </InputGroup>
      <InputGroup>
        <LabeledInputBlock
          id="Settings-working-hours"
          text="Working hours"
          description="The time frame during which you are usually looking to work in."
          optional={false}
        >
          <div className="Settings-working-hours-input-container">
            <div>
              <TimeInput
                value={workingHoursFrom}
                onChange={(newValue) => {
                  setWorkingHoursFrom(newValue)

                  if (newValue > workingHoursTo) {
                    setWorkingHoursTo(newValue)
                  }
                }}
                required
              />
            </div>
            <div>
              -
            </div>
            <div>
              <TimeInput
                value={workingHoursTo}
                onChange={setWorkingHoursTo}
                min={workingHoursFrom}
                required
              />
            </div>
          </div>
        </LabeledInputBlock>
      </InputGroup>
      <InputGroup>
        <LabeledInputBlock
          id="Settings-days-off"
          text="Days off"
          description="Which days of the week are you off?"
          optional={false}
        >
          <div className="Settings-days-off-container">
            {[
              ['Monday', 'monday'],
              ['Tuesday', 'tuesday'],
              ['Wednesday', 'wednesday'],
              ['Thursday', 'thursday'],
              ['Friday', 'friday'],
              ['Saturday', 'saturday'],
              ['Sunday', 'sunday'],
            ].map((labelValue) => (
              <div key={labelValue[1]}>
                <WeekdayCheckbox
                  label={labelValue[0]}
                  value={labelValue[1] as keyof WeekdaySelection}
                  weekdays={weekdays}
                  setWeekdays={setWeekdays}
                />
              </div>
            ))}
          </div>
        </LabeledInputBlock>
      </InputGroup>
      <ActionRow>
        <Button
          submit
          disabled={updatingSettings}
        >
          Save changes
        </Button>
      </ActionRow>
    </Form>
  )
}

function WeekdayCheckbox(
  { label, value, weekdays, setWeekdays }:
  {
    label: string;
    value: keyof WeekdaySelection;
    weekdays: WeekdaySelection;
    setWeekdays: (newValue: WeekdaySelection) => void;
  },
) {
  return (
    <LabeledCheckbox
      labelText={label}
      checked={weekdays[value]}
      onChange={(isChecked) => setWeekdays({ ...weekdays, [value]: isChecked })}
    />
  )
}

export default SettingsForm
