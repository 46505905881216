import React from 'react'
import { LabeledInputBlock, NumberInput } from '../form'

function FrequencyInput(
  { inputRef, onChange, value }:
  {
    value: string;
    onChange: (newValue: string) => void;
    inputRef: React.RefObject<HTMLInputElement>;
  },
) {
  const id = 'project-frequency'

  return (
    <LabeledInputBlock
      id={id}
      text="Frequency"
      description="Do you plan to work on this project every day? Every 3rd day? ..."
      optional
    >
      <NumberInput
        inputRef={inputRef}
        onChange={onChange}
        value={value}
        id={id}
        min="1"
      />
    </LabeledInputBlock>
  )
}

export default FrequencyInput
