import { Project, AppState, ImagesIndex, Settings } from './types'
import GeneralSettingsTab from './EditProjectForm/GeneralSettingsTab'

function EditProjectForm(
  { closeModal, project, updateAppState, appState, imagesIndex, settings }:
  {
    closeModal: () => void;
    project: Project;
    updateAppState: () => void;
    appState: AppState;
    imagesIndex: ImagesIndex;
    settings: Settings;
  },
) {
  return (
    <div>
      <GeneralSettingsTab
        closeModal={closeModal}
        project={project}
        updateAppState={updateAppState}
        appState={appState}
        settings={settings}
      />
    </div>
  )
}

export default EditProjectForm
