import { LabeledCheckbox } from '../form'

function StreakCheckbox(
  { checkboxRef, onChange, checked }:
  {
    checked: boolean;
    onChange: () => void;
    checkboxRef: React.RefObject<HTMLInputElement>;
  },
) {
  return (
    <LabeledCheckbox
      labelText="Streak"
      labelDescription="If you really want to push yourself to completing this project, make it a streak! However, be aware that too many streaks can cause a lot of stress."
      checkboxRef={checkboxRef}
      onChange={onChange}
      checked={checked}
      id="project-streak"
    />
  )
}

export default StreakCheckbox
