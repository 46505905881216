import React from 'react'

function HtmlButton({ onClick, title, className, children, disabled, submit }) {
  const type = submit ? 'submit' : 'button'

  return (
    <button
      type={type}
      onClick={onClick}
      className={className}
      title={title}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default HtmlButton
