import HtmlButton from './HtmlButton'

import './Button.css'

type ButtonStyles = 'dark' | 'grande'

function Button(
  { onClick, title, children, submit, drag, styles = [], disabled }:
  {
    onClick?: () => void;
    title?: String;
    children: React.ReactNode,
    submit?: boolean;
    drag?: boolean;
    styles?: ButtonStyles[];
    disabled?: boolean;
  },
) {
  const classes = ['Button']

  if (drag) {
    classes.push('drag')
  }

  styles.forEach(style => {
    classes.push(`Button-${style}`)
  })

  return (
    <HtmlButton
      onClick={onClick}
      title={title}
      className={classes.join(' ')}
      disabled={disabled}
      submit
    >
      {children}
    </HtmlButton>
  )
}

export default Button
