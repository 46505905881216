import ProjectSelector from './ProjectSelector'
import './Menu.css'

function Menu({ appState, updateAppState, setProjectsDict, projectsDict, activityStatistics, settings, imagesIndex, backgroundImageId, globalActivityInMinutes }) {
  return (
    <div className="Menu">
      <div className="Menu-projects-selector-container">
        <ProjectSelector
          appState={appState}
          updateAppState={updateAppState}
          setProjectsDict={setProjectsDict}
          projectsDict={projectsDict}
          activityStatistics={activityStatistics}
          settings={settings}
          imagesIndex={imagesIndex}
          backgroundImageId={backgroundImageId}
          globalActivityInMinutes={globalActivityInMinutes}
        />
      </div>
    </div>
  )
}

export default Menu
