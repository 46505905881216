import React from 'react'
import HtmlInput from './HtmlInput'

function NumberInput(
  { inputRef, value, onChange, id, min, max }:
  {
    inputRef?: React.RefObject<HTMLInputElement>;
    value?: string;
    onChange?: (newValue: string) => void;
    id?: string;
    min?: string;
    max?: string;
  },
) {
  return (
    <HtmlInput
      type="number"
      inputRef={inputRef}
      value={value}
      onChange={(event) => onChange && onChange(event.currentTarget.value)}
      min={min}
      max={max}
      id={id}
    />
  )
}

export default NumberInput
