import React from 'react'
import styles from './ColorPalette.module.scss'
import { Color } from './types'

const ColorCard = ({ color }: { color: Color }) => {
  return (
    <div className={styles.colorFill} style={{ backgroundColor: color.hexCode }} />
  )
}

const ColorPalette = ({ colorPalette }: { colorPalette: Color[] }) => {
  return (
    <div className={styles.container}>
      {colorPalette.map(color => <ColorCard key={color.hexCode} color={color} />)}
    </div>
  )
}

export default ColorPalette
