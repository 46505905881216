import { gql } from '@apollo/client'

export const FETCH_SESSIONS_QUERY = gql`
  query FetchSessions {
    sessions {
      id
      startedAt
      endedAt
      projectId
      summary
      notes
    }
  }
`

export const FETCH_TODOS_QUERY = gql`
  query FetchTodos($projectId: String!) {
    todos(projectId: $projectId) {
      id
      projectId
      completedAt
      description
      notes
      priority
    }

    completedTodos(projectId: $projectId) {
      id
      projectId
      completedAt
      description
      notes
      priority
    }
  }
`

export const CREATE_TODO_MUTATION = gql`
  mutation CreateTodo($projectId: String!, $description: String!, $notes: String!, $priority: Int!) {
    createTodo(projectId: $projectId, description: $description, notes: $notes, priority: $priority) {
      todo {
        id
        projectId
        completedAt
        description
        notes
        priority
      }
      errors
    }
  }
`

export const FETCH_IMAGES_QUERY = gql`
  query FetchImages {
    getImages {
      id
      url
      urlXs
      display
      backgroundColor
      tags
    }
  }
`

export const FETCH_IMAGE_IDS_FOR_PROJECT_QUERY = gql`
  query FetchImageIdsForProject($projectId: String!) {
    getImages(projectId: $projectId) {
      id
    }
  }
`

export const CREATE_IMAGE_MUTATION = gql`
  mutation CreateImage($projectId: String!, $imageBase64: String!, $fileName: String!, $fileType: String!, $display: String!, $backgroundColor: String!, $tags: String!) {
    createImageAndLinkToProject(projectId: $projectId, imageBase64: $imageBase64, fileName: $fileName, fileType: $fileType, display: $display, backgroundColor: $backgroundColor, tags: $tags) {
      image {
        id
        url
        urlXs
        display
        backgroundColor
        tags
      }
      errors {
        field
        messages
      }
    }
  }
`

export const UPDATE_IMAGE_MUTATION = gql`
  mutation UpdateImage($id: String!, $display: String!, $backgroundColor: String!, $tags: String!) {
    updateImage(id: $id, display: $display, backgroundColor: $backgroundColor, tags: $tags) {
      image {
        id
        url
        urlXs
        display
        backgroundColor
        tags
      }
      errors {
        field
        messages
      }
    }
  }
`

export const DELETE_IMAGE_MUTATION = gql`
  mutation DeleteImage($id: String!) {
    deleteImage(id: $id)
  }
`

export const REGISTER_USER_MUTATION = gql`
  mutation RegisterUser(
    $email: String!,
    $password: String!
  ) {
    registerUser(
      email: $email,
      password: $password
    ) {
      user {
        id
        email
      }
      errors
    }
  }
`

export const UPDATE_TODO_MUTATION = gql`
  mutation UpdateTodo($id: String!, $projectId: String!, $description: String!, $notes: String!) {
    updateTodo(id: $id, projectId: $projectId, description: $description, notes: $notes) {
      id
      projectId
      completedAt
      description
      notes
      priority
    }
  }
`

export const COMPLETE_TODO_MUTATION = gql`
  mutation CompleteTodo($id: String!, $completedAt: String!) {
    completeTodo(id: $id, completedAt: $completedAt) {
      todo {
        id
        projectId
        completedAt
        description
        notes
        priority
      }
      errors
    }
  }
`

export const UNCOMPLETE_TODO_MUTATION = gql`
  mutation UncompleteTodo($id: String!) {
    uncompleteTodo(id: $id) {
      todo {
        id
        projectId
        completedAt
        description
        notes
        priority
      }
      errors
    }
  }
`

export const SET_TODO_PRIORITY_MUTATION = gql`
  mutation SetTodoPriority($id: String!, $priority: Int!) {
    setTodoPriority(id: $id, priority: $priority) {
      todo {
        id
        projectId
        completedAt
        description
        notes
        priority
      }
      errors
    }
  }
`

export const UPDATE_APP_STATE_MUTATION = gql`
  mutation UpdateAppState($startedAt: String, $projectId: String, $summary: String!, $notes: String!, $countdownStartedAt: String, $countdownLength: Int) {
    updateAppState(startedAt: $startedAt, projectId: $projectId, summary: $summary, notes: $notes, countdownStartedAt: $countdownStartedAt, countdownLength: $countdownLength) {
      startedAt
      projectId
      summary
      notes
      countdownStartedAt
      countdownLength
    }
  }
`

export const CREATE_SESSION_QUERY = gql`
  mutation CreateSession($startedAt: String!, $endedAt: String!, $projectId: String, $summary: String!, $notes: String!) {
    createSession(startedAt: $startedAt, endedAt: $endedAt, projectId: $projectId, summary: $summary, notes: $notes) {
      id
      startedAt
      endedAt
      projectId
      summary
      notes
    }
  }
`

export const UPDATE_SESSION_MUTATION = gql`
  mutation UpdateSession($id: String!, $startedAt: String!, $endedAt: String!, $projectId: String, $summary: String!, $notes: String!) {
    updateSession(id: $id, startedAt: $startedAt, endedAt: $endedAt, projectId: $projectId, summary: $summary, notes: $notes) {
      id
      startedAt
      endedAt
      projectId
      summary
      notes
    }
  }
`

export const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateProject(
    $id: String!,
    $params: UpdateProject!,
  ) {
    updateProject(
      id: $id,
      params: $params,
    ) {
      project {
        id
        name
        description
        notes
        active
        streak
        frequency
        wallpaperImageId
        countsTowardsGlobalMinutesPerDay
        buttonBackgroundImageId
        lowestVisibilityUntil
        focus
        minutesPerDay
        colorPalette {
          hexCode
        }
        projectType
        daysOff {
          modus
          weekly {
            selectedWeekdays {
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              sunday
            }
          }
        }
        workingHours {
          from
          to
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export const APP_STATE_QUERY = gql`
  query AppState {
    appState {
      startedAt
      projectId
      summary
      notes
      countdownStartedAt
      countdownLength
    }
    getSettings {
      timeOfDaybreak
      minutesPerDay
      daysOff {
        modus
        weekly {
          selectedWeekdays {
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
            sunday
          }
        }
      }
      workingHours {
        from
        to
      }
    }
  }
`

export const FETCH_PROJECTS_QUERY = gql`
  query AllProjects {
    projects {
      id
      name
      description
      notes
      frequency
      active
      streak
      focus
      wallpaperImageId
      countsTowardsGlobalMinutesPerDay
      buttonBackgroundImageId
      lowestVisibilityUntil
      minutesPerDay
      colorPalette {
        hexCode
      }
      projectType
      daysOff {
        modus
        weekly {
          selectedWeekdays {
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
            sunday
          }
        }
      }
      workingHours {
        from
        to
      }
    }
  }
`

export const ADD_PROJECT_QUERY = gql`
  mutation AddProject(
    $name: String!,
    $description: String!,
    $notes: String!,
    $active: Boolean!,
    $frequency: Int,
  ) {
    createProject(
      newProject: {
        name: $name,
        description: $description,
        notes: $notes,
        active: $active,
        frequency: $frequency,
      }
    ) {
      project {
        id
        name
        description
        notes
        active
        frequency
        streak
        minutesPerDay
        focus
        wallpaperImageId
        countsTowardsGlobalMinutesPerDay
        buttonBackgroundImageId
        lowestVisibilityUntil
        colorPalette {
          hexCode
        }
        projectType
        daysOff {
          modus
          weekly {
            selectedWeekdays {
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              sunday
            }
          }
        }
        workingHours {
          from
          to
        }
      }
      errors
    }
  }
`

export const DELETE_PROJECT_QUERY = gql`
  mutation DeleteProject($id: String!) {
    deleteProject(id: $id)
  }
`

export const DELETE_TODO_MUTATION = gql`
  mutation DeleteTodo($id: String!) {
    deleteTodo(id: $id)
  }
`

export const DELETE_SESSION_QUERY = gql`
  mutation DeleteSession($id: String!) {
    deleteSession(id: $id)
  }
`

export const UPDATE_SETTINGS_MUTATION = gql`
  mutation UpdateSettings(
    $newSettings: NewSettings!
  ) {
    updateSettings(
      newSettings: $newSettings
    ) {
      settings {
        timeOfDaybreak
        minutesPerDay
        daysOff {
          modus
          weekly {
            selectedWeekdays {
                monday
                tuesday
                wednesday
                thursday
                friday
                saturday
                sunday
            }
          }
        }
        workingHours {
          from
          to
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export const LINK_IMAGE_TO_PROJECT_MUTATION = gql`
  mutation LinkImageToProject(
    $projectId: String!,
    $imageId: String!,
  ) {
    linkImageToProject(
        projectId: $projectId,
        imageId: $imageId,
    )
  }
`

export const UNLINK_IMAGE_FROM_PROJECT_MUTATION = gql`
  mutation UnlinkImageFromProject(
    $projectId: String!,
    $imageId: String!,
  ) {
    unlinkImageFromProject(
        projectId: $projectId,
        imageId: $imageId,
    )
  }
`
