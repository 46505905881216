import { LabeledCheckbox } from '../form'

function CountsTowardsGlobalMinutesPerDayCheckbox(
  { checkboxRef, onChange, checked }:
  {
    checkboxRef: React.RefObject<HTMLInputElement>;
    checked: boolean;
    onChange: () => void;
  },
) {
  return (
    <LabeledCheckbox
      labelText="Counts towards overall minutes per day"
      labelDescription="If unchecked, this project's activity will not be deducted from the targeted amount of minutes per day. You may set that target on the 'Settings' page."
      checkboxRef={checkboxRef}
      onChange={onChange}
      checked={checked}
      id="project-counts-towards-global-minutes-per-day"
    />
  )
}

export default CountsTowardsGlobalMinutesPerDayCheckbox
