import React from 'react'
import HtmlButton from './HtmlButton'

function LinkButton({ onClick, children, submit, styles = [] }) {
  const classes = ['Link']

  styles.forEach(style => {
    classes.push(`Link-${style}`)
  })

  return (
    <HtmlButton
      onClick={onClick}
      submit={submit}
      className={classes.join(' ')}
    >
      {children}
    </HtmlButton>
  )
}

export default LinkButton
