import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { DateTime } from 'luxon'
import {
  DELETE_PROJECT_QUERY,
  UPDATE_PROJECT_MUTATION,
  FETCH_PROJECTS_QUERY,
  FETCH_SESSIONS_QUERY,
} from '../queries'
import { updateProjectCache, buildUpdateProjectParams } from '../utilities'
import { dateIsUpcoming } from '../utilities2'
import {
  InputGroup,
  Form,
  ActionRow,
} from '../form'
import { Button } from '../clickables'
import {
  NameInput,
  DescriptionInput,
  FrequencyInput,
  MinutesPerDayInput,
  ActiveCheckbox,
  ColorPaletteInput,
  StreakCheckbox,
  ProjectTypeInput,
  FocusCheckbox,
  WorkingHoursInput,
  DaysOffInput,
  LowestVisibilityUntilInput,
  CountsTowardsGlobalMinutesPerDayCheckbox,
} from '../project_form'

function GeneralSettingsTab({ closeModal, project, updateAppState, appState, settings }) {
  const [validationErrors, setValidationErrors] = useState({})
  const [editedProject, setEditedProject] = useState({
    ...project,
    frequency: project.frequency ? project.frequency.toString() : '',
    minutesPerDay: project.minutesPerDay ? project.minutesPerDay.toString() : '',
    lowestVisibilityUntil: (project.lowestVisibilityUntil && dateIsUpcoming(project.lowestVisibilityUntil, DateTime.now(), settings.timeOfDaybreak)) ? project.lowestVisibilityUntil : null,
  })

  const [deleteProject] = useMutation(DELETE_PROJECT_QUERY, {
    update(cache) {
      // Remove all sessions for the deleted project, as the server has done the same.
      const { sessions } = cache.readQuery({ query: FETCH_SESSIONS_QUERY })
      cache.writeQuery({
        query: FETCH_SESSIONS_QUERY,
        data: { sessions: sessions.filter(session => session.projectId !== project.id) },
      })

      // Remove the projects from all projects.
      const { projects } = cache.readQuery({ query: FETCH_PROJECTS_QUERY })
      cache.writeQuery({
        query: FETCH_PROJECTS_QUERY,
        data: { projects: projects.filter(projectX => projectX.id !== project.id) },
      })

      if (appState.projectId === project.id) {
        updateAppState({ projectId: null })
      }
    },
  })

  const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION, {
    update(cache, data) { updateProjectCache(cache, data) },
  })

  const deleteSelectedProject = () => {
    const choiceConfirmed = window.confirm(`Do you really want to delete "${project.name}"? All recorded sessions for this project will be deleted along with it.`)

    if (!choiceConfirmed) return

    deleteProject({ variables: { id: project.id } })

    closeModal()
  }

  const submitForm = async(event) => {
    event.preventDefault()

    setValidationErrors({})

    const variables = buildUpdateProjectParams(editedProject, {
      name: editedProject.name.trim(),
      description: editedProject.description.trim(),
      active: editedProject.active,
      frequency: editedProject.frequency.trim() === '' ? null : parseInt(editedProject.frequency),
      minutesPerDay: editedProject.minutesPerDay.trim() === '' ? null : parseInt(editedProject.minutesPerDay),
      colorPalette: editedProject.colorPalette.map((color) => ({ hexCode: color.hexCode })),
    })

    const { data, error } = await updateProject({ variables })

    if (!data) return console.error('Unexpected error', error)

    const { updateProject: { errors } } = data

    if (errors && errors.length > 0) {
      const newValidationErrors = {}

      errors.forEach((error) => {
        if (!(error.field in newValidationErrors)) {
          newValidationErrors[error.field] = []
        }

        newValidationErrors[error.field] = newValidationErrors[error.field].concat(error.messages)
      })

      setValidationErrors(newValidationErrors)
    } else {
      closeModal()
    }
  }

  return (
    <Form onSubmit={submitForm}>
      <InputGroup>
        <NameInput
          value={editedProject.name}
          onChange={name => setEditedProject({ ...editedProject, name })}
        />
      </InputGroup>
      <InputGroup>
        <DescriptionInput
          value={editedProject.description}
          onChange={description => setEditedProject({ ...editedProject, description })}
        />
      </InputGroup>
      <InputGroup>
        <ProjectTypeInput
          value={editedProject.projectType}
          onChange={(newValue) => setEditedProject({ ...editedProject, projectType: newValue })}
        />
      </InputGroup>
      <InputGroup validationErrors={validationErrors.minutesPerDay}>
        <MinutesPerDayInput
          value={editedProject.minutesPerDay}
          onChange={newValue => setEditedProject({ ...editedProject, minutesPerDay: newValue })}
        />
      </InputGroup>
      <InputGroup validationErrors={validationErrors.frequency}>
        <FrequencyInput
          value={editedProject.frequency}
          onChange={newValue => setEditedProject({ ...editedProject, frequency: newValue })}
        />
      </InputGroup>
      <InputGroup>
        <LowestVisibilityUntilInput
          value={editedProject.lowestVisibilityUntil || ''}
          onChange={(newValue) => { newValue.trim() === '' ? setEditedProject({ ...editedProject, lowestVisibilityUntil: null }) : setEditedProject({ ...editedProject, lowestVisibilityUntil: newValue }) }}
          settings={settings}
        />
      </InputGroup>
      <InputGroup>
        <FocusCheckbox
          checked={editedProject.focus}
          onChange={() => setEditedProject({ ...editedProject, focus: !editedProject.focus })}
        />
      </InputGroup>
      <InputGroup>
        <StreakCheckbox
          checked={editedProject.streak}
          onChange={() => setEditedProject({ ...editedProject, streak: !editedProject.streak })}
        />
      </InputGroup>
      <InputGroup validationErrors={validationErrors.colorPalette}>
        <ColorPaletteInput
          initialValue={editedProject.colorPalette}
          onChange={colorPalette => setEditedProject({ ...editedProject, colorPalette })}
        />
      </InputGroup>
      <InputGroup>
        <WorkingHoursInput
          value={editedProject.workingHours}
          onChange={(newWorkingHours) => setEditedProject({ ...editedProject, workingHours: newWorkingHours })}
        />
      </InputGroup>
      <InputGroup>
        <DaysOffInput
          value={editedProject.daysOff}
          onChange={(newDaysOff) => setEditedProject({ ...editedProject, daysOff: newDaysOff })}
        />
      </InputGroup>
      <InputGroup>
        <CountsTowardsGlobalMinutesPerDayCheckbox
          checked={editedProject.countsTowardsGlobalMinutesPerDay}
          onChange={() => setEditedProject({ ...editedProject, countsTowardsGlobalMinutesPerDay: !editedProject.countsTowardsGlobalMinutesPerDay })}
        />
      </InputGroup>
      <InputGroup>
        <ActiveCheckbox
          checked={!editedProject.active}
          onChange={() => setEditedProject({ ...editedProject, active: !editedProject.active })}
        />
      </InputGroup>
      <ActionRow>
        <Button submit>Save changes</Button>
        <Button onClick={deleteSelectedProject}>Delete current project</Button>
      </ActionRow>
    </Form>
  )
}

export default GeneralSettingsTab
