import React, { useState } from 'react'
import { DateTime } from 'luxon'
import { Button } from './clickables'
import './OneClickDemoAccessButton.css'

function OneClickDemoAccessButton({ traxApiDemoSignInUrl, authenticationStatusChanged }) {
  const [isLoading, setIsLoading] = useState(false)

  const demoSignIn = async() => {
    setIsLoading(true)

    const response = await fetch(traxApiDemoSignInUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        most_recent_midnight: DateTime.local().startOf('day').toUTC().toISO(),
      }),
    })
    const jsonResponse = await response.json()

    if (!jsonResponse.data.createDemoAppSession.success) {
      console.error('Signing in failed unexpectedly', jsonResponse.data)
    }

    authenticationStatusChanged()

    setIsLoading(false)
  }

  return (
    <Button
      onClick={demoSignIn}
      styles={['dark', 'grande']}
      disabled={isLoading}
    >
      1-Click<br />
      Demo Access
    </Button>
  )
}

export default OneClickDemoAccessButton
