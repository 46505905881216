import HtmlInput from './HtmlInput'

function EmailInput({ inputRef, value, onChange, id, required }) {
  return (
    <HtmlInput
      type="email"
      inputRef={inputRef}
      value={value}
      onChange={onChange}
      id={id}
      required={required}
      autoComplete="email"
    />
  )
}

export default EmailInput
