import { LabeledInputBlock, SelectInput } from '../form'

function ProjectTypeInput(
  { onChange, value }:
  {
    value: string;
    onChange: (newValue: string) => void;
  },
) {
  const id = 'project-type'

  return (
    <LabeledInputBlock
      id={id}
      text="Project type"
      description="The type you assign to this project will affect its visibility, among others. For example a work project will not be visible on days off."
      optional={false}
    >
      <SelectInput
        value={value}
        onChange={onChange}
        options={[
          { value: 'WORK', label: 'Work' },
          { value: 'TIME_OFF', label: 'Time Off' },
          { value: 'CARE', label: 'Care' },
        ]}
      />
    </LabeledInputBlock>
  )
}

export default ProjectTypeInput
