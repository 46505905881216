import React from 'react'
import { DateTime } from 'luxon'
import { Link } from './clickables'
import logo from './logo.svg'
import './Footer.css'

function Footer() {
  const date = DateTime.local()

  return (
    <div className="Footer">
      <div className="Footer-headline">
        <div className="Footer-logo-container">
          <img src={logo} alt="Logo" />
        </div>
        <div>
          {date.toFormat('yyyy/MM')}
        </div>
      </div>
      <div className="Footer-spacer">
        ·
      </div>
      <div>
        Feedback or questions? <Link label="Write us!" href="mailto:trax-official@posteo.de" />
      </div>
    </div>
  )
}

export default Footer
