import { DateTime } from 'luxon'
import { sessionDuration } from './utilities'
import { isDayOff, localStartTimeOfDate, dateOfTime } from './utilities2'
import { Id, Session, Streak, Settings, StreakableProject } from './types'

type DateId = Id;
type ProjectId = Id;
type DateActivity = { [key: DateId]: number };
type ProjectActivity = { [key: ProjectId]: DateActivity };

class ActivityStatistics {
  streakTolerance: number
  settings: Settings
  relativeActivityInterval: number
  dailyActivity: ProjectActivity
  weeklyActivity: ProjectActivity
  streaks: { [key: ProjectId]: { [key: DateId]: { [key: string]: { [key: number]: { [key: number]: Streak } } } } }

  constructor(
    { streakTolerance, settings, relativeActivityInterval }:
    { streakTolerance: number; settings: Settings; relativeActivityInterval: number; },
  ) {
    this.streakTolerance = streakTolerance
    this.settings = settings
    this.relativeActivityInterval = relativeActivityInterval
    this.dailyActivity = {}
    this.weeklyActivity = {}
    this.streaks = {}
  }

  // Dates are identified by their ISO code.
  dateCode(startedAt: DateTime) {
    return dateOfTime(startedAt, this.settings)
  }

  // Weeks are identified by the ISO code of their Monday.
  weekCode(startedAt: DateTime) {
    const [offsetHours, offsetMinutes] = this.settings.timeOfDaybreak.split(':').map((stringValue) => parseInt(stringValue))
    const offsetCorrected = startedAt.toLocal().minus({ hours: offsetHours, minutes: offsetMinutes })

    return offsetCorrected.startOf('week').toISODate()
  }

  _dailyActivity(dailyActivity: DateActivity, dateCodeOfLastDay: DateId, numberOfDays: number) {
    const activity = []
    let date = DateTime.fromISO(dateCodeOfLastDay)

    for (let i = 0; i < numberOfDays; i++) {
      const dateCode = date.toISODate()
      const activityForDate = dailyActivity[dateCode] == null ? 0 : dailyActivity[dateCode]

      activity.unshift([dateCode, activityForDate])

      date = date.minus({ days: 1 })
    }

    return activity
  }

  _weeklyActivity(weeklyActivity: DateActivity, weekCodeOfLastWeek: DateId, numberOfWeeks: number) {
    const activity = []
    let date = DateTime.fromISO(weekCodeOfLastWeek)

    for (let i = 0; i < numberOfWeeks; i++) {
      const weekCode = date.toISODate()
      const activityForWeek = weeklyActivity[weekCode] == null ? 0 : weeklyActivity[weekCode]

      activity.unshift([weekCode, activityForWeek])

      date = date.minus({ weeks: 1 })
    }

    return activity
  }

  _recordActivity(activityObject: ProjectActivity, projectKey: ProjectId, code: DateId, duration: number) {
    if (!activityObject[projectKey]) {
      activityObject[projectKey] = {}
    }

    if (activityObject[projectKey][code] == null) {
      activityObject[projectKey][code] = 0
    }

    activityObject[projectKey][code] += duration
  }

  dailyActivityForProject(projectId: ProjectId) {
    return this.dailyActivity[projectId] || {}
  }

  weeklyActivityForProject(projectId: ProjectId) {
    return this.weeklyActivity[projectId] || {}
  }

  addSession(session: Session) {
    const projectId = session.projectId
    const duration = sessionDuration(session).as('minutes')
    const dateCode = this.dateCode(DateTime.fromISO(session.startedAt))
    const weekCode = this.weekCode(DateTime.fromISO(session.startedAt))

    if (projectId) {
      delete this.streaks[projectId]
    }

    this._recordActivity(this.dailyActivity, 'total', dateCode, duration)
    this._recordActivity(this.weeklyActivity, 'total', weekCode, duration)

    if (!projectId) return

    this._recordActivity(this.dailyActivity, projectId, dateCode, duration)
    this._recordActivity(this.weeklyActivity, projectId, weekCode, duration)
  }

  // Get the daily activity over all projects for `numberOfDays` number of consecutive weeks. The
  // last week being `dateCodeOfLastDay`.
  dailyActivityTotal(dateCodeOfLastDay: DateId, numberOfDays: number) {
    return this._dailyActivity(this.dailyActivity.total || {}, dateCodeOfLastDay, numberOfDays)
  }

  // Get the daily activity of project `projectId` for `numberOfDays` number of consecutive weeks.
  // The last week being `dateCodeOfLastDay`.
  dailyActivityProject(projectId: ProjectId, dateCodeOfLastDay: DateId, numberOfDays: number) {
    return this._dailyActivity(this.dailyActivity[projectId] || {}, dateCodeOfLastDay, numberOfDays)
  }

  // Get the weekly activity over all projects for `numberOfWeeks` number of consecutive weeks. The
  // last week being `weekCodeOfLastWeek`.
  weeklyActivityTotal(weekCodeOfLastWeek: DateId, numberOfWeeks: number) {
    return this._weeklyActivity(this.weeklyActivity.total || {}, weekCodeOfLastWeek, numberOfWeeks)
  }

  // Get the weekly activity of project `projectId` for `numberOfWeeks` number of consecutive weeks.
  // The last week being `weekCodeOfLastWeek`.
  weeklyActivityProject(projectId: ProjectId, weekCodeOfLastWeek: DateId, numberOfWeeks: number) {
    return this._weeklyActivity(
      this.weeklyActivity[projectId] || {},
      weekCodeOfLastWeek,
      numberOfWeeks,
    )
  }

  // Divides the number of active days within the `this.relativeActivityInterval` days up to and
  // including `keyTime` by `this.relativeActivityInterval` for the given project.
  relativeActivity(projectId: ProjectId, keyTime: DateTime) {
    let date = DateTime.fromISO(this.dateCode(keyTime))

    const dailyActivity = this.dailyActivity[projectId]

    if (!dailyActivity) return 0

    let daysActive = 0

    for (let i = 0; i < this.relativeActivityInterval; i++) {
      const dateCode = date.toISODate()
      const activityForDate = dailyActivity[dateCode] == null ? 0 : dailyActivity[dateCode]

      if (activityForDate > 0) {
        daysActive += 1
      }

      date = date.minus({ days: 1 })
    }

    return daysActive / this.relativeActivityInterval
  }

  activityForProjectOnDay(projectId: ProjectId, dateId: DateId): number {
    return this.dailyActivityForProject(projectId)[dateId] || 0.0
  }

  streak(
    { project, atDateTime }:
    { project: StreakableProject; atDateTime: DateTime },
  ): Streak | undefined {
    const {
      id: projectId,
      frequency,
      minutesPerDay,
    } = project

    if (!minutesPerDay) return undefined

    const settingsKey = JSON.stringify({ ...this.settings, daysOff: project.daysOff })
    const dateKey = this.dateCode(atDateTime)

    if (projectId in this.streaks && dateKey in this.streaks[projectId] && settingsKey in this.streaks[projectId][dateKey] && frequency in this.streaks[projectId][dateKey][settingsKey] && (minutesPerDay || 0) in this.streaks[projectId][dateKey][settingsKey][frequency]) {
      return this.streaks[projectId][dateKey][settingsKey][frequency][minutesPerDay]
    }

    const streak = this._calculateStreak({ project, atDateTime })

    this.streaks[projectId] ||= {}
    this.streaks[projectId][dateKey] ||= {}
    this.streaks[projectId][dateKey][settingsKey] ||= {}
    this.streaks[projectId][dateKey][settingsKey][frequency] ||= {}
    this.streaks[projectId][dateKey][settingsKey][frequency][minutesPerDay] = streak

    return streak
  }

  _fulfilledMinutesPerDayForProjectForDate = (projectId: ProjectId, dateTime: DateTime, minutesPerDay: number | null) => {
    const dateCode = this.dateCode(dateTime)
    const activityOnDate = this.dailyActivityForProject(projectId)[dateCode] || 0

    if (minutesPerDay && minutesPerDay > 0) {
      return activityOnDate >= minutesPerDay
    }

    return activityOnDate > 0
  }

  _calculateStreak(
    { project, atDateTime }:
    { project: StreakableProject; atDateTime: DateTime },
  ): Streak {
    const {
      id: projectId,
      frequency,
      minutesPerDay,
    } = project
    const tolerance = this.streakTolerance
    const dailyActivityForProject = this.dailyActivityForProject(projectId)

    let streak: Streak = {
      length: 0,
      missed: 0,
      nextSessionDueInDays: 0,
      numberOfDaysSinceLastSession: null,
    }

    if (!dailyActivityForProject || Object.keys(dailyActivityForProject).length === 0) {
      return streak
    }

    const sortedDateCodes = Object.keys(dailyActivityForProject).sort()
    const oldestDateCode = sortedDateCodes[0]

    const fulfilledMinutesPerDayToday = this._fulfilledMinutesPerDayForProjectForDate(projectId, atDateTime, minutesPerDay)
    let date = localStartTimeOfDate(oldestDateCode, this.settings.timeOfDaybreak)
    let currentStreak = { done: 0, length: 0, missed: 0 }
    if (fulfilledMinutesPerDayToday) streak.numberOfDaysSinceLastSession = 0

    while (date <= atDateTime.minus({ day: 1 })) {
      const isADayOff = project.daysOff && isDayOff(date, project.daysOff, this.settings)

      if (this._fulfilledMinutesPerDayForProjectForDate(projectId, date, minutesPerDay)) {
        streak.numberOfDaysSinceLastSession = 0
        currentStreak.done += 1
        if (!isADayOff) currentStreak.length += 1
      } else {
        if (isADayOff) {
        } else {
          if (streak.numberOfDaysSinceLastSession !== null) {
            streak.numberOfDaysSinceLastSession += 1
          }

          const sessionDueToday = streak.numberOfDaysSinceLastSession === null || streak.numberOfDaysSinceLastSession >= frequency
          if (!sessionDueToday) {
            currentStreak.length += 1
          } else if ((currentStreak.missed + 1) / (currentStreak.length + 1) <= tolerance) {
            currentStreak.length += 1
            currentStreak.missed += 1
          } else {
            currentStreak = { done: 0, length: 0, missed: 0 }
          }
        }
      }

      date = date.plus({ days: 1 })
    }

    const isADayOff = project.daysOff && isDayOff(date, project.daysOff, this.settings)
    if (this._fulfilledMinutesPerDayForProjectForDate(projectId, date, minutesPerDay)) {
      streak.numberOfDaysSinceLastSession = 0
      currentStreak.done += 1
      if (!isADayOff) currentStreak.length += 1
    } else {
      const isADayOff = project.daysOff && isDayOff(date, project.daysOff, this.settings)

      if (isADayOff) {
      } else {
        if (streak.numberOfDaysSinceLastSession !== null) {
          streak.numberOfDaysSinceLastSession += 1
        }

        const sessionDueToday = streak.numberOfDaysSinceLastSession === null || streak.numberOfDaysSinceLastSession >= frequency

        if (!sessionDueToday) {
          currentStreak.length += 1
        }
      }
    }

    streak.length = currentStreak.length
    streak.missed = currentStreak.missed

    if (streak.length > 0) {
      streak.rate = (streak.length - streak.missed) / streak.length
    }

    if (streak.numberOfDaysSinceLastSession !== null) {
      streak.nextSessionDueInDays = Math.max(frequency - streak.numberOfDaysSinceLastSession, 0)
    }

    return streak
  }
}

export default ActivityStatistics
