import { useQuery } from '@apollo/client'
import { APP_STATE_QUERY } from '../queries'

function useAppState() {
  const { data, refetch } = useQuery(APP_STATE_QUERY)
  const appState = data ? data.appState : null
  const settings = data ? data.getSettings : null

  return {
    appState,
    settings,
    refetch,
  }
}

export default useAppState
