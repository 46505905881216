import { LabeledInputBlock, NumberInput } from '../form'

function MinutesPerDayInput(
  { onChange, value }:
  {
    value: string;
    onChange: (newValue: string) => void;
  },
) {
  const id = 'project-minutes-per-day'

  return (
    <LabeledInputBlock
      id={id}
      text="Minutes per day"
      description="The amount of minutes you aim spending on this project per day"
      optional
    >
      <NumberInput
        onChange={onChange}
        value={value}
        id={id}
        min="1"
      />
    </LabeledInputBlock>
  )
}

export default MinutesPerDayInput
