import { useEffect } from 'react'
import { Image as ImageType, Id, ImagesIndex, ModalArguments } from './types'
import useProjectImages from './hooks/useProjectImages'
import IconButton from './clickables/IconButton'
import { FaRegImage, FaPlus } from 'react-icons/fa6'
import './ImageBrowser.css'

function ImageBrowser(
  { backgroundImageId, setBackgroundImageId, dashboardMuted, setDashboardMuted, projectId, imagesIndex, openModal }:
  {
    backgroundImageId?: Id;
    setBackgroundImageId: (id: Id | null) => void;
    dashboardMuted: boolean;
    setDashboardMuted: (url: boolean) => void;
    projectId?: Id;
    imagesIndex: ImagesIndex;
    openModal: (args: ModalArguments) => void;
  },
) {
  const images = useProjectImages({ projectId: projectId, imagesIndex })

  const selectPicture = (image: ImageType) => {
    const isCurrentlySelected = backgroundImageId === image.id

    setBackgroundImageId(isCurrentlySelected ? null : image.id)

    if (!isCurrentlySelected) {
      setDashboardMuted(true)
    }
  }

  useEffect(() => {
    if (!dashboardMuted) return

    const handler = () => {
      setDashboardMuted(false)
    }

    document.addEventListener('mousedown', handler)

    return () => {
      document.removeEventListener('mousedown', handler)
    }
  }, [dashboardMuted, setDashboardMuted])

  useEffect(() => {
    if (!backgroundImageId) setDashboardMuted(false)
  }, [backgroundImageId, setDashboardMuted])

  return (
    <div className="ImageBrowser">
      {images.map((image) => (
        <div className="ImageBrowser-img-and-actions-container" key={image.id}>
          <button
            className="ImageBrowser-img-container"
            onClick={() => selectPicture(image)}
            onMouseDown={(event) => backgroundImageId !== image.id && event.stopPropagation()}
          >
            <img src={image.urlXs} alt="" />
            {backgroundImageId === image.id && (
              <div className="ImageBrowser-img-container-border">
                <div className="ImageBrowser-img-container-border2">
                  <div className="ImageBrowser-img-container-border3" />
                </div>
              </div>
            )}
          </button>
          {backgroundImageId === image.id && (
            <div className="ImageBrowser-edit-button-container">
              <IconButton
                icon={<FaRegImage />}
                onClick={() => openModal(['editImage', image.id])}
                title="Edit image"
                theme="ice"
                text="Edit"
              />
            </div>
          )}
        </div>
      ))}
      {projectId && (
        <IconButton
          icon={<FaPlus />}
          onClick={() => openModal(['linkImageToProject', projectId])}
          title="Add an image"
          theme="ice"
        />
      )}
    </div>
  )
}

export default ImageBrowser
