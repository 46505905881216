import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  APP_STATE_QUERY,
  UPDATE_APP_STATE_MUTATION,
  FETCH_IMAGES_QUERY,
} from './queries'
import { PivotalExecutioner, useSyncNotifier, useInterval } from './utilities'
import { determineIsTimeOff, dateOfTime } from './utilities2'
import Modal from './Modal'
import Header from './Header'
import NewProjectForm from './NewProjectForm'
import EditProjectForm from './EditProjectForm'
import LandingPage from './LandingPage'
import Dashboard from './Dashboard'
import Menu from './Menu'
import NewUserForm from './NewUserForm'
import SettingsForm from './SettingsForm'
import AddImageToProject from './AddImageToProject'
import EditImage from './EditImage'
import useInputFieldAutoUpdate from './hooks/useInputFieldAutoUpdate'
import { ImageDisplayOption } from './types'
import Backdrop from './Backdrop'
import './Router.css'
import landingPageWallpaper from './david-clode-75CxJTYeUYs-unsplash.jpg'
import useAppState from './hooks/useAppState'
import { DateTime } from 'luxon'

let appStateLocal = null

function Router({ traxApiSignInUrl, traxApiSignOutUrl, traxApiDemoSignInUrl }) {
  const projectBackground = useRef(null)
  const {
    appState: remoteAppState,
    settings,
    refetch,
  } = useAppState()
  const [selectedProject, setSelectedProject] = useState(null)
  const [modalStack, setModalStack] = useState([])
  const [projectsDict, setProjectsDict] = useState(null)
  const [appState, setAppState] = useState(null)
  const [activityStatistics, setActivityStatistics] = useState(null)
  const [pivotalExecutioner] = useState(new PivotalExecutioner({ waitFor: 1000 }))
  const [isSyncingAppState, setIsSyncingAppState] = useState(false)
  const [syncAppStateError, setSyncAppStateError] = useState(null)
  const [flashMessages, setFlashMessages] = useState([])
  const [backgroundImageId, setBackgroundImageId] = useState(null)
  const [backgroundImageSetForProjectId, setBackgroundImageSetForProjectId] = useState(null)
  const [globalActivityInMinutes, setGlobalActivityInMinutes] = useState(0)
  const [isMuted, setIsMuted] = useState(false)
  const [isTimeOff, setIsTimeOff] = useState(false)
  const [
    updateRemoteAppState,
    {
      loading: updatingRemoteAppState,
      error: updateRemoteAppStateError,
    },
  ] = useMutation(
    UPDATE_APP_STATE_MUTATION,
    {
      update(cache, { data: { updateAppState } }) {
        const appStateResult = cache.readQuery({ query: APP_STATE_QUERY })

        cache.writeQuery({
          query: APP_STATE_QUERY,
          data: { ...appStateResult, appState: updateAppState },
        })
      },
    }
  )

  // Initialize `appState` and `appStateLocal`.
  useEffect(() => {
    if (appStateLocal !== null || !remoteAppState) return

    appStateLocal = { ...remoteAppState }
    setAppState({ ...remoteAppState })
  }, [remoteAppState])

  const { data: imagesData } = useQuery(FETCH_IMAGES_QUERY, { skip: !remoteAppState })
  const imagesIndex = useMemo(() => {
    if (!imagesData) return {}

    const images = imagesData.getImages

    if (!images) return {}

    const index = {}

    for (let i = 0; i < images.length; i++) {
      index[images[i].id] = images[i]
    }

    return index
  }, [imagesData])
  const backgroundImage = backgroundImageId ? imagesIndex[backgroundImageId] : null

  const determineAndSetIsTimeOff = (settings, selectedProject) => {
    if (!settings || !selectedProject) {
      setIsTimeOff(false)
      return
    }

    setIsTimeOff(determineIsTimeOff(settings, selectedProject))
  }

  useEffect(() => {
    determineAndSetIsTimeOff(settings, selectedProject)
  }, [settings, selectedProject])

  useInterval(() => {
    determineAndSetIsTimeOff(settings, selectedProject)
  }, (settings && selectedProject) ? 60_000 : null)

  useSyncNotifier(
    setIsSyncingAppState,
    setSyncAppStateError,
    updatingRemoteAppState,
    updateRemoteAppStateError,
  )

  useEffect(() => {
    if (!selectedProject || selectedProject.id !== backgroundImageSetForProjectId) {
      setBackgroundImageId(null)
      setBackgroundImageSetForProjectId(selectedProject ? selectedProject.id : null)
    }
  }, [selectedProject, backgroundImageSetForProjectId])

  const calculateGlobalActivityInMinutes = useCallback(() => {
    if (!projectsDict || !activityStatistics) return

    const dateIdToday = dateOfTime(DateTime.now(), settings)
    let value = 0

    for (const [_projectId, project] of Object.entries(projectsDict)) {
      if (project.countsTowardsGlobalMinutesPerDay) {
        value += activityStatistics.activityForProjectOnDay(project.id, dateIdToday)
      }
    }

    setGlobalActivityInMinutes(value)
  }, [projectsDict, activityStatistics, settings])

  useEffect(() => {
    calculateGlobalActivityInMinutes()
  }, [calculateGlobalActivityInMinutes])

  useInterval(() => {
    calculateGlobalActivityInMinutes()
  }, 60_000)

  const retry = async() => {
    try {
      await refetch()
    } catch (error) {
      console.error(error)
    }
  }

  const openModal = modal => {
    setModalStack([modal, ...modalStack])
  }

  const closeModal = () => {
    setModalStack(modalStack.slice(1))
  }

  const updateAppState = (updatedAttributes = {}) => {
    appStateLocal = {
      ...appStateLocal,
      ...updatedAttributes,
    }

    setAppState({ ...appStateLocal })
    pivotalExecutioner.execute(
      () => updateRemoteAppState({ variables: { ...appStateLocal } })
    )
  }

  const updateAppStateNotes = (newNotes) => {
    appStateLocal = {
      ...appStateLocal,
      notes: newNotes,
    }

    setAppState({ ...appStateLocal })
  }
  const updateRemoteAppStateMutationVariablesForNewNotes = useCallback((newNotes) => {
    return {
      ...appStateLocal,
      notes: newNotes,
    }
  }, [])
  const { outOfSync: sessionNotesOutOfSync } = useInputFieldAutoUpdate({
    inputFieldValue: appStateLocal ? appStateLocal.notes : '',
    storedValueAsInputFieldValue: remoteAppState ? remoteAppState.notes : '',
    mutation: updateRemoteAppState,
    buildMutationVariablesWithNewValue: updateRemoteAppStateMutationVariablesForNewNotes,
  })

  const updateAppStateSummary = (newSummary) => {
    appStateLocal = {
      ...appStateLocal,
      summary: newSummary,
    }

    setAppState({ ...appStateLocal })
  }
  const updateRemoteAppStateMutationVariablesForNewSummary = useCallback((newSummary) => {
    return {
      ...appStateLocal,
      summary: newSummary,
    }
  }, [])
  const { outOfSync: sessionSummaryOutOfSync } = useInputFieldAutoUpdate({
    inputFieldValue: appStateLocal ? appStateLocal.summary : '',
    storedValueAsInputFieldValue: remoteAppState ? remoteAppState.summary : '',
    mutation: updateRemoteAppState,
    buildMutationVariablesWithNewValue: updateRemoteAppStateMutationVariablesForNewSummary,
  })

  const modal = () => {
    if (!modalStack[0]) return null

    switch (modalStack[0][0]) {
    case 'newProject':
      return (
        <Modal headline="New project" closeModal={closeModal}>
          <NewProjectForm closeModal={closeModal} />
        </Modal>
      )
    case 'editProject':
      if (!selectedProject) return null

      return (
        <Modal headline={`Edit "${selectedProject.name}"`} closeModal={closeModal}>
          <EditProjectForm
            closeModal={closeModal}
            project={selectedProject}
            appState={appState}
            updateAppState={updateAppState}
            imagesIndex={imagesIndex}
            settings={settings}
          />
        </Modal>
      )
    case 'settings':
      return (
        <Modal headline="Settings" closeModal={closeModal}>
          <SettingsForm settings={settings} closeModal={closeModal} />
        </Modal>
      )
    case 'signUp':
      return (
        <Modal headline="Create an account" closeModal={closeModal}>
          <NewUserForm onRegistered={closeModal} />
        </Modal>
      )
    case 'linkImageToProject':
      return (
        <Modal headline="Add an image" closeModal={closeModal}>
          <AddImageToProject imagesIndex={imagesIndex} projectId={modalStack[0][1]} />
        </Modal>
      )
    case 'editImage':
      return (
        <Modal headline="Edit image" closeModal={closeModal}>
          <EditImage image={imagesIndex[modalStack[0][1]]} project={selectedProject} closeModal={closeModal} />
        </Modal>
      )
    default:
      return null
    }
  }

  const addFlashMessage = (type, message) => {
    const id = `${type}-${message}-${new Date().getTime()}`
    const timeoutHandler = setTimeout(
      () => { setFlashMessages([]) },
      4000,
    )
    const flashMessage = { id, timeoutHandler, type, message }

    if (flashMessages.length > 0) {
      clearTimeout(flashMessages[0].timeoutHandler)
    }

    setFlashMessages([flashMessage])

    return id
  }

  const determineWallpaperImage = () => {
    if (!appState) {
      return {
        id: '2',
        url: landingPageWallpaper,
        display: ImageDisplayOption.CoverAvailableSpace,
      }
    }

    if (!selectedProject) return null

    return imagesIndex[selectedProject.wallpaperImageId]
  }

  const syncError = syncAppStateError
  const isSyncing = isSyncingAppState

  return (
    <div className="Router">
      <div ref={projectBackground} className="Router-wallpaper-container">
        <Backdrop
          backgroundColor="rgb(27, 0, 60)"
          wallpaperImage={determineWallpaperImage()}
          backgroundImages={backgroundImage ? [backgroundImage] : []}
        />
      </div>
      <div className="Flash-messages-container">
        {flashMessages.map(flashMessage => (
          <span key={flashMessage.id} className={`App-flash-label ${flashMessage.type}`}>{flashMessage.message}</span>
        ))}
        {syncError && (
          <span className="App-sync-status-label error">Couldn't sync!</span>
        )}
        {isSyncing && (
          <span className="App-sync-status-label danger">Syncing...</span>
        )}
      </div>
      {appState && (
        <Dashboard
          appState={appState}
          openModal={openModal}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          updateAppState={updateAppState}
          projectsDict={projectsDict}
          activityStatistics={activityStatistics}
          setActivityStatistics={setActivityStatistics}
          addFlashMessage={addFlashMessage}
          updateAppStateNotes={updateAppStateNotes}
          sessionNotesOutOfSync={sessionNotesOutOfSync}
          updateAppStateSummary={updateAppStateSummary}
          sessionSummaryOutOfSync={sessionSummaryOutOfSync}
          backgroundImageId={backgroundImageId}
          settings={settings}
          isMuted={isMuted}
          imagesIndex={imagesIndex}
          setIsMuted={setIsMuted}
          setBackgroundImageId={(imageId) => {
            setBackgroundImageId(imageId)
            setBackgroundImageSetForProjectId(selectedProject ? selectedProject.id : null)
          }}
          isTimeOff={isTimeOff}
        />
      )}
      {appState ? (
        <Header
          openModal={openModal}
          traxApiSignOutUrl={traxApiSignOutUrl}
          selectedProject={selectedProject}
          isMuted={isMuted}
          settings={settings}
          globalActivityInMinutes={globalActivityInMinutes}
        >
          <Menu
            appState={appState}
            updateAppState={updateAppState}
            setProjectsDict={setProjectsDict}
            projectsDict={projectsDict}
            activityStatistics={activityStatistics}
            settings={settings}
            imagesIndex={imagesIndex}
            backgroundImageId={backgroundImageId}
            globalActivityInMinutes={globalActivityInMinutes}
          />
        </Header>
      ) : (
        <Header
          openModal={openModal}
          traxApiSignOutUrl={traxApiSignOutUrl}
          fullScreen
        >
          <LandingPage
            authenticationStatusChanged={retry}
            traxApiSignInUrl={traxApiSignInUrl}
            traxApiDemoSignInUrl={traxApiDemoSignInUrl}
            openModal={openModal}
          />
        </Header>
      )}
      {modal()}
    </div>
  )
}

export default Router
