import { useState, useRef, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { DateTime } from 'luxon'
import { FETCH_SESSIONS_QUERY, CREATE_SESSION_QUERY } from './queries'
import { useInterval, msInMSM, minutesAndSecondsString } from './utilities'
import IconButton from './clickables/IconButton'
import { MultilineTextInput } from './form'
import {
  FaStop,
  FaStopwatch,
  FaRegHourglassHalf,
  FaPlay,
  FaVolumeXmark,
  FaPause,
  FaXmark,
} from 'react-icons/fa6'
import classNames from 'classnames'
import SectionContainer from './SectionContainer'
import './Stopwatch.css'

const INTERVAL_LENGTH = 100

const updateSessionsCache = (cache, { data: { createSession } }) => {
  const { sessions } = cache.readQuery({ query: FETCH_SESSIONS_QUERY })
  cache.writeQuery({
    query: FETCH_SESSIONS_QUERY,
    data: { sessions: [createSession].concat(sessions) },
  })
}

const DescribedMetric = ({ children, label }) => {
  return (
    <div className="DescribedMetric">
      <div className="DescribedMetric-value">
        {children}
      </div>
      <div className="DescribedMetric-label">
        {label}
      </div>
    </div>
  )
}

const useHighFrequencyInterval = (callbacks, { noUpdatesDue }) => {
  const INTERVAL_LENGTH = 200

  useInterval(() => {
    for (const callback of callbacks) {
      callback()
    }

  }, noUpdatesDue ? null : INTERVAL_LENGTH)
}

function Stopwatch({
  appState,
  updateAppState,
  addFlashMessage,
  updateAppStateNotes,
  sessionNotesOutOfSync,
  updateAppStateSummary,
  sessionSummaryOutOfSync,
  isTimeOff,
}) {
  const [countdownEndedState, setCountdownEndedState] = useState(false)
  const [countdownAlertMuted, setCountdownAlertMuted] = useState(false)
  const countdownContainer = useRef(null)
  const passedTimeContainer = useRef(null)
  const [countdownInputValue, setCountdownInputValue] = useState('')
  const countdownLength = useMemo(() => {
    const parsedLength = Number.parseInt(countdownInputValue)

    if (!parsedLength || parsedLength < 0) {
      return null
    }

    return parsedLength
  }, [countdownInputValue])
  const [createSession] = useMutation(CREATE_SESSION_QUERY, {
    onError: error => {
      addFlashMessage('error', 'Failed to complete session (no connection?). Please try again.')
    },
  })

  const stopwatchRunning = appState && appState.startedAt
  const countdownStarted = appState && appState.countdownStartedAt
  const countdownPaused = appState && !appState.countdownStartedAt && appState.countdownLength
  const estimatedCountdownEnd = countdownStarted ? DateTime.fromISO(appState.countdownStartedAt).plus({ milliseconds: appState.countdownLength }) : null

  const setTime = (hours, minutes, seconds) => {
    if (!passedTimeContainer.current) return
    passedTimeContainer.current.innerHTML = minutesAndSecondsString(hours, minutes, seconds)
  }

  const setTimeLeft = (hours, minutes, seconds) => {
    if (!countdownContainer || !countdownContainer.current) return
    countdownContainer.current.innerHTML = minutesAndSecondsString(hours, minutes, seconds)
  }

  const confirmCountdownEnded = () => {
    setCountdownEndedState(false)
  }

  const ensureStopwatchStarted = () => {
    if (stopwatchRunning) return

    updateAppState({ startedAt: DateTime.local().toISO() })
  }

  const ensureStopwatchStopped = () => {
    if (!stopwatchRunning) return

    const variables = {
      startedAt: DateTime.fromISO(appState.startedAt).toISO(),
      endedAt: DateTime.local().toISO(),
      projectId: appState.projectId,
      summary: appState.summary.trim(),
      notes: appState.notes.trim(),
    }

    createSession({
      variables,
      update(cache, data) {
        updateSessionsCache(cache, data)

        setTimeout(() => setTime(0, 0, 0), INTERVAL_LENGTH)

        updateAppState({
          startedAt: null,
          summary: '',
          notes: '',
        })
      },
    })
  }

  const pauseCountdownIfStarted = () => {
    if (!countdownStarted) return

    const countdownStartedAt = DateTime.fromISO(appState.countdownStartedAt)
    const msSinceCountdownStart = DateTime.utc().diff(countdownStartedAt)
    const msRemaining = appState.countdownLength - msSinceCountdownStart

    updateAppState({
      countdownStartedAt: null,
      countdownLength: msRemaining,
    })
  }

  const startCountdownIfPrepared = () => {
    if (countdownPaused) {
      updateAppState({
        countdownStartedAt: DateTime.utc().toISO(),
      })
    } else {
      if (!countdownLength) return

      updateAppState({
        countdownStartedAt: DateTime.utc().toISO(),
        countdownLength: countdownLength * 60 * 1000,
      })

      setCountdownInputValue('')
    }
  }

  const resetCountdown = () => {
    updateAppState({
      countdownStartedAt: null,
      countdownLength: null,
    })

    setCountdownInputValue('')
  }

  useHighFrequencyInterval(
    [
      () => {
        if (!appState.startedAt) return

        const startedAt = DateTime.fromISO(appState.startedAt)
        const timePassed = DateTime.local().diff(startedAt)
        const [hours, minutes, seconds] = msInMSM(timePassed)

        setTime(hours, minutes, seconds)
      },
      () => {
        if (!appState.countdownStartedAt) return

        const countdownStartedAt = DateTime.fromISO(appState.countdownStartedAt)
        const msSinceCountdownStart = DateTime.utc().diff(countdownStartedAt)
        const msRemaining = appState.countdownLength - msSinceCountdownStart

        if (msRemaining <= 0) {
          updateAppState({
            countdownStartedAt: null,
            countdownLength: null,
          })
          setCountdownEndedState(true)
        }

        const msRemainingDisplay = Math.ceil(Math.max(msRemaining, 0) / 1000) * 1000
        const [hours, minutes, seconds] = msInMSM(msRemainingDisplay)

        setTimeLeft(hours, minutes, seconds)
      },
    ],
    {
      noUpdatesDue: !((appState && appState.countdownStartedAt) || stopwatchRunning),
    }
  )

  const clickStopwatchStart = () => {
    ensureStopwatchStarted()
    startCountdownIfPrepared()
    confirmCountdownEnded()
  }

  const clickStopwatchStop = () => {
    ensureStopwatchStopped()
    pauseCountdownIfStarted()
    confirmCountdownEnded()
  }

  const clickToggleStopwatch = () => {
    if (stopwatchRunning) {
      clickStopwatchStop()
    } else {
      clickStopwatchStart()
    }
  }

  const clickCountdownPause = () => {
    if (countdownPaused) {
      startCountdownIfPrepared()
    } else {
      pauseCountdownIfStarted()
    }
  }

  const clickCountdownStart = () => {
    startCountdownIfPrepared()
  }

  const clickCountdownReset = () => {
    resetCountdown()
    confirmCountdownEnded()
  }

  const minutesInputKeyDown = event => {
    // Don't do anything if it wasn't RETURN that was pressed.
    if (event.keyCode !== 13) return

    if (event.metaKey) {
      startCountdownIfPrepared()
    } else {
      clickStopwatchStart()
    }
  }

  const summaryInputKeyDown = event => {
    // Don't do anything if it wasn't RETURN that was pressed.
    if (event.keyCode !== 13) return

    clickToggleStopwatch()
  }

  return (
    <div className="Stopwatch">
      <SectionContainer fullWidth>
        <div className="Session-container">
          <div className="Session-container-main-row">
            <div className="Session-container-main-row-input-container">
              <input
                onChange={(event) => updateAppStateSummary(event.currentTarget.value)}
                onKeyDown={summaryInputKeyDown}
                value={appState ? appState.summary : ''}
                className={`Session-container-main-row-input ${sessionSummaryOutOfSync ? 'input-out-of-sync' : ''}`}
                placeholder="Session summary"
              />
            </div>
            <div>
              {stopwatchRunning ? (
                <IconButton
                  icon={<FaStop />}
                  text="Stop"
                  onClick={clickStopwatchStop}
                  title="Submit the running session"
                  theme="purple-pressed"
                />
              ) : (
                <IconButton
                  icon={<FaPlay />}
                  text="Start"
                  onClick={clickStopwatchStart}
                  title="Start a new session"
                  theme="ice"
                />
              )}
            </div>
            <DescribedMetric
              label={<>Session length <FaStopwatch /></>}
            >
              <div ref={passedTimeContainer} className={classNames('Session-container-session-length', { 'Session-container-session-length-mute': !stopwatchRunning })}>
                00:00
              </div>
            </DescribedMetric>
          </div>
          <div className="Session-container-notes-row">
            <MultilineTextInput
              onChange={updateAppStateNotes}
              value={appState ? appState.notes : ''}
              placeholder="Session notes"
              fullWidth
              fullHeight
              outOfSync={sessionNotesOutOfSync}
              disableResizing
            />
          </div>
        </div>
      </SectionContainer>
      <div className="Utils-section-container-container">
        <SectionContainer>
          <div className="Utils-section-container">
            <DescribedMetric
              label={
                <>
                  {estimatedCountdownEnd ? estimatedCountdownEnd.toFormat('HH:mm') : 'Timer'}
                  {' '}
                  <FaRegHourglassHalf />
                </>
              }
            >
              {(countdownStarted || countdownPaused || countdownEndedState) ? (
                <>
                  <div
                    ref={countdownContainer}
                    className={classNames('Utils-section-countdown-time-left', { 'Utils-section-countdown-time-left-muted': countdownPaused })}
                  >
                    00:00
                  </div>
                  {(countdownEndedState && !countdownAlertMuted) && <audio src="countdown-ended-v1_3.mp3" volume={0.3} autoPlay />}
                </>
              ) : (
                <input
                  value={countdownInputValue}
                  onChange={(event) => setCountdownInputValue(event.currentTarget.value)}
                  onKeyDown={minutesInputKeyDown}
                  type="number"
                  min="0"
                  className="Utils-section-container-countdown-length-input"
                  placeholder="0"
                />
              )}
            </DescribedMetric>
            <div className="Stopwatch-time-bar-stopwatch-actions-section">
              {(countdownStarted || countdownEndedState) ? (
                <IconButton
                  icon={<FaPause />}
                  text="Pause"
                  onClick={clickCountdownPause}
                  title="Pause timer"
                  theme="purple-pressed"
                  disabled={countdownEndedState}
                />
              ) : (
                <IconButton
                  icon={<FaPlay />}
                  text="Start"
                  onClick={clickCountdownStart}
                  title="Start timer"
                  theme="ice"
                  disabled={!countdownLength && !countdownPaused}
                />
              )}
              <IconButton
                icon={<FaXmark />}
                text="Reset"
                onClick={clickCountdownReset}
                title="Reset timer"
                theme="ice"
                disabled={!(countdownStarted || countdownPaused || countdownEndedState || countdownLength != null)}
              />
            </div>
            <div className="Stopwatch-time-bar-stopwatch-actions-section">
              <IconButton
                icon={<FaVolumeXmark />}
                text={countdownAlertMuted ? 'Unmute' : 'Mute'}
                onClick={() => setCountdownAlertMuted(!countdownAlertMuted)}
                title="Submit the running session"
                theme={countdownAlertMuted ? 'purple-pressed' : 'ice'}
              />
            </div>
          </div>
        </SectionContainer>
      </div>
    </div>
  )
}

export default Stopwatch
