import React, { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import './WeeklyActivity.css'

function WeeklyActivity({ activityStatistics, selectedProject }) {
  const [activityTotal, setActivityTotal] = useState(null)
  const [activityProject, setActivityProject] = useState(null)

  useEffect(() => {
    const codeOfLastWeek = activityStatistics.weekCode(DateTime.local())
    const activityTotal = activityStatistics.weeklyActivityTotal(codeOfLastWeek, 4)

    setActivityTotal(activityTotal)
  }, [activityStatistics])

  useEffect(() => {
    if (selectedProject) {
      const codeOfLastWeek = activityStatistics.weekCode(DateTime.local())
      const activityProject = activityStatistics.weeklyActivityProject(
        selectedProject.id,
        codeOfLastWeek,
        4
      )

      setActivityProject(activityProject)
    } else {
      setActivityProject(null)
    }
  }, [activityStatistics, selectedProject])

  if (!activityTotal) return null

  return (
    <div className="WeeklyActivity">
      {activityTotal.map((weekActivity, index) => (
        <div key={weekActivity[0]}>
          <div className="WeeklyActivity-week-number">
            Week {DateTime.fromISO(weekActivity[0]).weekNumber}
          </div>
          <div>{Math.round(weekActivity[1] / 60 * 10) / 10} h</div>
          {activityProject && (
            <div>{Math.round(activityProject[index][1] / 60 * 10) / 10} h</div>
          )}
        </div>
      ))}
    </div>
  )
}

export default WeeklyActivity
