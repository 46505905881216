import React from 'react'
import { Label, Checkbox } from '../form'

function LabeledCheckbox(
  { id, checked, onChange, checkboxRef, labelText, labelDescription, optional }:
  {
    id?: string;
    checked: boolean;
    onChange: (isChecked: boolean) => void;
    checkboxRef?: React.RefObject<HTMLInputElement>;
    labelText: string;
    labelDescription?: string;
    optional?: boolean;
  },
) {
  return (
    <Label htmlFor={id} description={labelDescription} optional={optional}>
      {labelText}
      <Checkbox
        checkboxRef={checkboxRef}
        checked={checked}
        onChange={onChange}
        id={id}
      />
    </Label>
  )
}

export default LabeledCheckbox
