import React from 'react'
import DailyActivity from './ActivitySummary/DailyActivity'
import WeeklyActivity from './ActivitySummary/WeeklyActivity'

function ActivitySummary({ selectedProject, activityStatistics }) {
  return (
    <div>
      {activityStatistics && (
        <DailyActivity activityStatistics={activityStatistics} selectedProject={selectedProject} />
      )}
      {activityStatistics && (
        <WeeklyActivity activityStatistics={activityStatistics} selectedProject={selectedProject} />
      )}
    </div>
  )
}

export default ActivitySummary
