import React from 'react'

function HtmlInput(
  { type, inputRef, onChange, onKeyDown, id, min, max, value, required, className, placeholder, autoComplete, accept }:
  {
    type: 'text' | 'number' | 'file' | 'time' | 'date',
    inputRef?: React.RefObject<HTMLInputElement>,
    onChange?: (event: React.FormEvent<HTMLInputElement>) => void,
    onKeyDown?: (event: React.FormEvent<HTMLInputElement>) => void,
    id?: string,
    min?: string,
    max?: string,
    value?: string,
    required?: boolean,
    className?: string,
    placeholder?: string,
    autoComplete?: 'new-password' | 'current-password' | 'email',
    accept?: string,
  },
) {
  return (
    <input
      type={type}
      ref={inputRef}
      onChange={onChange}
      onKeyDown={onKeyDown}
      className={className}
      value={value}
      id={id}
      min={min}
      max={max}
      required={required}
      placeholder={placeholder}
      autoComplete={autoComplete}
      accept={accept}
    />
  )
}

export default HtmlInput
