import React, { useState, useRef } from 'react'
import { Button, LinkButton } from './clickables'
import { Errors, PasswordInput } from './form'
import './SignIn.css'

function SignIn({ traxApiSignInUrl, authenticationStatusChanged, openModal }) {
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const emailInput = useRef(null)
  const passwordInput = useRef(null)

  const signIn = async event => {
    event.preventDefault()

    setIsLoading(true)
    setErrors([])

    const email = emailInput.current.value
    const password = passwordInput.current.value
    const response = await fetch(traxApiSignInUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
      credentials: 'include',
    })
    const result = (await response.json()).data.createAppSession

    if (result.success) {
      authenticationStatusChanged()
    } else {
      setErrors(result.errors)
    }

    setIsLoading(false)
  }

  return (
    <form className="SignIn" onSubmit={signIn}>
      <Errors messages={errors} />
      <div className="input-group">
        <input ref={emailInput} className="dark" id="SignIn-email" placeholder="Email" />
      </div>
      <div className="input-group">
        <PasswordInput
          inputRef={passwordInput}
          className="dark"
          id="SignIn-password"
          placeholder="Password"
          required
        />
      </div>
      <div className="SignIn-submit">
        <Button disabled={isLoading} styles={['dark']} submit>Sign in</Button>
      </div>
      <LinkButton onClick={() => openModal(['signUp'])} styles={['on-black', 'sized']}>Create account</LinkButton>
    </form>
  )
}

export default SignIn
