import { useState, useEffect, useRef } from 'react'
import Footer from './Footer'
import { Project, Settings } from './types'
import { Button } from './clickables'
import logo from './logo.svg'
import burger from './burger.svg'
import classNames from 'classnames'
import './Header.css'

function AccountDropdown(
  { openModal, traxApiSignOutUrl, selectedProject }:
  {
    openModal: (modal: string[]) => void;
    traxApiSignOutUrl: string;
    selectedProject: Project | null;
  },
) {
  const clickNewProject = () => {
    openModal(['newProject'])
  }

  const clickEditCurrentProject = () => {
    openModal(['editProject'])
  }

  const clickSettings = () => {
    openModal(['settings'])
  }

  const signOut = async() => {
    await fetch(traxApiSignOutUrl, {
      method: 'DELETE',
      credentials: 'include',
    })

    window.location.reload()
  }

  return (
    <div className="Menu-dropdown">
      {selectedProject && (
        <Button onClick={clickEditCurrentProject}>Edit "{selectedProject.name}"</Button>
      )}
      <Button onClick={clickNewProject}>New Project</Button>
      <Button onClick={clickSettings}>Settings</Button>
      <Button onClick={signOut}>Sign Out</Button>
    </div>
  )
}

function TraxButton(
  { openModal, traxApiSignOutUrl, selectedProject }:
  {
    openModal: (modal: string[]) => void;
    traxApiSignOutUrl: string;
    selectedProject: Project | null;
  },
) {
  const [accountDropdownVisible, setAccountDropdownVisible] = useState(false)
  const menuButton = useRef<HTMLDivElement>(null)
  let buttonClass = 'Header-logo-button-container'
  if (accountDropdownVisible) buttonClass += ' Header-logo-button-container-active'

  const handleClickAnywhere = (event: any) => {
    if (menuButton && menuButton.current && !menuButton.current.contains(event.target)) {
      setAccountDropdownVisible(false)
    }
  }

  // Attach global click event listener.
  useEffect(() => {
    document.addEventListener('click', handleClickAnywhere)

    return () => {
      document.removeEventListener('click', handleClickAnywhere)
    }
  }, [])

  return (
    <div
      ref={menuButton}
      className={buttonClass}
      onClick={() => setAccountDropdownVisible(!accountDropdownVisible)}
    >
      <div className="Sticks-icon-container">
        <img src={burger} alt="Account menu" />
      </div>
      <div className="Header-logo-container">
        <div>
          <div className="Header-logo">
            <img src={logo} alt="Logo" />
          </div>
        </div>
      </div>
      {accountDropdownVisible && (
        <AccountDropdown
          openModal={openModal}
          traxApiSignOutUrl={traxApiSignOutUrl}
          selectedProject={selectedProject}
        />
      )}
    </div>
  )
}

function ProgressBar(
  { progress, minutesLeft }:
  {
    progress: number;
    minutesLeft: number;
  }) {
  const beyond = progress >= 1
  const barLength = beyond ? Math.min(100, Math.round((progress - 1) * 100)) : Math.round((1 - progress) * 100)

  return (
    <>
      <div
        className="Header-ProgressBar Header-ProgressBar-background"
        style={{ backgroundColor: beyond ? 'rgba(68, 0, 255, 0.15)' : 'rgba(0, 0, 0, 0.3)' }}
      >
        {[...Array(100)].map((_, index) => <span key={index} style={{ marginRight: 30 }}>{Math.round(minutesLeft)} min</span>)}
      </div>
      <div
        className="Header-ProgressBar Header-ProgressBar-progress-bar"
        style={{
          width: `${barLength}%`,
          backgroundColor: beyond ? '#4d000040' : 'rgba(19, 19, 19, 0.8)',
        }}
      >
        {[...Array(100)].map((_, index) => <span key={index} style={{ marginRight: 30 }}>{Math.round(minutesLeft)} min</span>)}
      </div>
    </>
  )
}

function Header(
  { fullScreen, children, openModal, traxApiSignOutUrl, selectedProject, isMuted, settings, globalActivityInMinutes }:
  {
    fullScreen: boolean;
    children: React.ReactNode;
    openModal: (modal: string[]) => void;
    traxApiSignOutUrl: string;
    selectedProject: Project | null;
    isMuted: boolean;
    settings?: Settings;
    globalActivityInMinutes: number;
  },
) {
  return (
    <div className={classNames('Header-container', { 'full-screen': fullScreen, 'Header-muted': isMuted })}>
      <div className="Header-sub-container">
        <TraxButton
          openModal={openModal}
          traxApiSignOutUrl={traxApiSignOutUrl}
          selectedProject={selectedProject}
        />
        <div className="Header-content">
          {settings && settings.minutesPerDay != null && (
            <div style={{ width: '100%', height: 7, position: 'relative' }}>
              <ProgressBar progress={globalActivityInMinutes / settings.minutesPerDay} minutesLeft={settings.minutesPerDay - globalActivityInMinutes} />
            </div>
          )}
          {children}
        </div>
      </div>
      {fullScreen && <Footer />}
    </div>
  )
}

export default Header
