import { LabeledCheckbox } from '../form'

function FocusCheckbox(
  { checkboxRef, onChange, checked }:
  {
    checked: boolean;
    onChange: () => void;
    checkboxRef: React.RefObject<HTMLInputElement>;
  },
) {
  return (
    <LabeledCheckbox
      labelText="Focus"
      labelDescription="Focused projects have a higher visibility"
      checkboxRef={checkboxRef}
      onChange={onChange}
      checked={checked}
      id="project-focus"
    />
  )
}

export default FocusCheckbox
