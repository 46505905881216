import React from 'react'
import classNames from 'classnames'
import HtmlInput from './HtmlInput'
import './FileInput.css'

function FileInput(
  { inputRef, onChange, id, label, disabled, accept }:
  {
    inputRef?: React.RefObject<HTMLInputElement>;
    label?: React.ReactNode;
    onChange?: (files: FileList | null) => void;
    id: string;
    disabled: boolean;
    accept?: string;
  },
) {
  return (
    <>
      <label
        htmlFor={id}
        className={classNames(
          'Button',
          {
            'FileInput-label-disabled': disabled,
          },
        )}
      >
        {label || 'Select file'}
      </label>
      <HtmlInput
        type="file"
        inputRef={inputRef}
        onChange={(event) => onChange && onChange(event.currentTarget.files)}
        id={id}
        className="FileInput-input"
        accept={accept}
      />
    </>
  )
}

export default FileInput
