import { LabeledInputBlock, LabeledCheckbox } from '../form'
import { RepeatCycle, WeekdaySelection } from '../types'

function WeekdayCheckbox(
  { label, value, weekdays, setWeekdays }:
  {
    label: string;
    value: keyof WeekdaySelection;
    weekdays: WeekdaySelection;
    setWeekdays: (newValue: WeekdaySelection) => void;
  },
) {
  return (
    <LabeledCheckbox
      labelText={label}
      checked={weekdays[value]}
      onChange={(isChecked) => setWeekdays({ ...weekdays, [value]: isChecked })}
    />
  )
}

function DaysOffInput(
  { onChange, value }:
  {
    onChange: (repeatCycle: RepeatCycle) => void;
    value?: RepeatCycle;
  },
) {
  const weekdays: WeekdaySelection = (value && value.weekly) ? value.weekly.selectedWeekdays : {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  }

  return (
    <LabeledInputBlock
      id="project-days-off"
      text="Days off"
      description="Which days of the week are you off?"
      optional={false}
    >
      <div className="Settings-days-off-container">
        {[
          ['Monday', 'monday'],
          ['Tuesday', 'tuesday'],
          ['Wednesday', 'wednesday'],
          ['Thursday', 'thursday'],
          ['Friday', 'friday'],
          ['Saturday', 'saturday'],
          ['Sunday', 'sunday'],
        ].map((labelValue) => (
          <div key={labelValue[1]}>
            <WeekdayCheckbox
              label={labelValue[0]}
              value={labelValue[1] as keyof WeekdaySelection}
              weekdays={weekdays}
              setWeekdays={(newWeekdaySelection) => onChange({ weekly: { selectedWeekdays: newWeekdaySelection } })}
            />
          </div>
        ))}
      </div>
    </LabeledInputBlock>
  )
}

export default DaysOffInput
