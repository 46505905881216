import React from 'react'
import SignIn from './SignIn'
import OneClickDemoAccessButton from './OneClickDemoAccessButton'
import './LandingPage.css'

function LandingPage({ authenticationStatusChanged, traxApiSignInUrl, traxApiDemoSignInUrl, openModal }) {
  return (
    <div className="LandingPage">
      <div className="LandingPage-content">
        <div className="LandingPage-headline">
          <h1>A Dedicated Space For Your Projects.</h1>
        </div>
        <div className="LandingPage-actions">
          <div className="LandingPage-demo-access">
            <OneClickDemoAccessButton
              traxApiDemoSignInUrl={traxApiDemoSignInUrl}
              authenticationStatusChanged={authenticationStatusChanged}
            />
          </div>
          <div className="LandingPage-or">
            <div className="LandingPage-or-stripe"></div>
            <div className="LandingPage-or-word">or</div>
            <div className="LandingPage-or-stripe"></div>
          </div>
          <div className="LandingPage-auth">
            <SignIn
              traxApiSignInUrl={traxApiSignInUrl}
              authenticationStatusChanged={authenticationStatusChanged}
              openModal={openModal}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPage
