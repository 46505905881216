import { Color } from '../../types'
import { hexColorToGrayscale, darkenHexColor } from '../../color_utils'
import './ProjectLinkColorPalette.css'

const ColorCard = ({ color }: { color: Color }) => {
  return (
    <div className="ProjectLinkColorPalette-color-fill" style={{ backgroundColor: color.hexCode }} />
  )
}

const determineColorPaletteDarkeningFactor = (visibilityLevel: number) => {
  const lowestFactor = 0.5
  const dynamicFactor = 1 - lowestFactor

  return Math.round((dynamicFactor * visibilityLevel + lowestFactor) * 10) / 10
}

function ProjectLinkColorPalette(
  { colorPalette, visibilityLevel }:
  { colorPalette: Color[]; visibilityLevel: number },
) {
  if (colorPalette.length === 0) return null

  let processedColorPalette = [...colorPalette]

  if (visibilityLevel < 1) {
    processedColorPalette = processedColorPalette.map((hexColorCode) => {
      const darkeningFactor = determineColorPaletteDarkeningFactor(visibilityLevel)
      const darkenedColor = darkenHexColor(hexColorToGrayscale(hexColorCode.hexCode), darkeningFactor)

      return ({ hexCode: darkenedColor })
    })
  }

  processedColorPalette.reverse()

  return (
    <div className="ProjectLinkColorPalette-container">
      <div className="ProjectLinkColorPalette-palette-container">
        {processedColorPalette.map((color, index) => (
          <ColorCard key={`${color.hexCode}-${index}`} color={color} />
        ))}
      </div>
    </div>
  )
}

export default ProjectLinkColorPalette
