import HtmlInput from './HtmlInput'

function PasswordInput({ inputRef, id, required, newPassword, className, placeholder }) {
  return (
    <HtmlInput
      type="password"
      inputRef={inputRef}
      className={className}
      id={id}
      placeholder={placeholder}
      required={required}
      autoComplete={newPassword ? 'new-password' : 'current-password'}
    />
  )
}

export default PasswordInput
