import { LabeledCheckbox } from '../form'

function ActiveCheckbox({ checkboxRef, onChange, checked }) {
  return (
    <LabeledCheckbox
      labelText="Archived"
      checkboxRef={checkboxRef}
      onChange={onChange}
      checked={checked}
      id="project-active"
    />
  )
}

export default ActiveCheckbox
