import { GetImageIdsResult, Id, LinkImageToProjectResult, UnlinkImageFromProjectResult, GetProjectsResult } from '../types'
import { useMutation } from '@apollo/client'
import { LINK_IMAGE_TO_PROJECT_MUTATION, UNLINK_IMAGE_FROM_PROJECT_MUTATION, FETCH_IMAGE_IDS_FOR_PROJECT_QUERY, FETCH_PROJECTS_QUERY } from '../queries'

function useImageProjectLinkers({ projectId, imageId }: { projectId: Id; imageId: Id }) {
  const [linkImageToProject, { loading: linkingImage }] = useMutation<LinkImageToProjectResult>(
    LINK_IMAGE_TO_PROJECT_MUTATION,
    {
      variables: {
        projectId,
        imageId,
      },
      update(cache, { data }) {
        if (!data || !data.linkImageToProject) return

        const idsForProjectResult = cache.readQuery<GetImageIdsResult>({
          query: FETCH_IMAGE_IDS_FOR_PROJECT_QUERY,
          variables: { projectId },
        })

        if (!idsForProjectResult || idsForProjectResult.getImages.find((imageIdOnly) => imageIdOnly.id === imageId)) return

        cache.writeQuery<GetImageIdsResult>({
          query: FETCH_IMAGE_IDS_FOR_PROJECT_QUERY,
          variables: { projectId },
          data: { getImages: [...idsForProjectResult.getImages, { id: imageId }] },
        })
      },
    },
  )
  const [unlinkImageFromProject, { loading: unlinkingImage }] = useMutation<UnlinkImageFromProjectResult>(
    UNLINK_IMAGE_FROM_PROJECT_MUTATION,
    {
      variables: {
        projectId,
        imageId,
      },
      update(cache, { data }) {
        if (!data || !data.unlinkImageFromProject) return

        const idsForProjectResult = cache.readQuery<GetImageIdsResult>({
          query: FETCH_IMAGE_IDS_FOR_PROJECT_QUERY,
          variables: { projectId },
        })

        if (!idsForProjectResult) return

        cache.writeQuery<GetImageIdsResult>({
          query: FETCH_IMAGE_IDS_FOR_PROJECT_QUERY,
          variables: { projectId },
          data: { getImages: [...idsForProjectResult.getImages].filter((imageIdOnly) => imageIdOnly.id !== imageId) },
        })

        //
        // Remove the unlinked image as wallpaper and background image from project
        //
        const projectsData = cache.readQuery<GetProjectsResult>({ query: FETCH_PROJECTS_QUERY })

        if (!projectsData) return

        const { projects } = projectsData

        const updatedProjects = [...projects]
        const projectIndex = updatedProjects.findIndex(updatedProject => updatedProject.id === projectId)
        const project = { ...projects[projectIndex] }

        if (project.wallpaperImageId === imageId) project.wallpaperImageId = null
        if (project.buttonBackgroundImageId === imageId) project.buttonBackgroundImageId = null

        updatedProjects[projectIndex] = project

        cache.writeQuery<GetProjectsResult>({
          query: FETCH_PROJECTS_QUERY,
          data: { projects: updatedProjects },
        })
      },
    },
  )
  const loading = linkingImage || unlinkingImage

  return {
    linkImageToProject: async() => (await linkImageToProject()).data?.linkImageToProject === true,
    unlinkImageFromProject: async() => (await unlinkImageFromProject()).data?.unlinkImageFromProject === true,
    loading,
  }
}

export default useImageProjectLinkers
