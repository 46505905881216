import HtmlInput from './HtmlInput'

function DateInput(
  { value, onChange, id, min, max }:
  {
    value: string;
    onChange: (newValue: string) => void;
    id: string;
    min?: string;
    max?: string;
  },
) {
  return (
    <HtmlInput
      type="date"
      value={value}
      onChange={(event) => onChange && onChange(event.currentTarget.value)}
      id={id}
      min={min}
      max={max}
    />
  )
}

export default DateInput
