type HexColor = string
type RgbColor = {
  r: number;
  g: number;
  b: number;
}

export const hexColorToRgb = (hexColorCodeNormalOrShort: HexColor): RgbColor => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i

  const hexColorCode = hexColorCodeNormalOrShort
    .replace(shorthandRegex, (_m, r, g, b) => {
      return r + r + g + g + b + b
    })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColorCode)

  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : {
    r: 0,
    g: 0,
    b: 0,
  }
}

export const rgbColorToHex = (rgbColor: RgbColor): HexColor => {
  return '#' + (1 << 24 | rgbColor.r << 16 | rgbColor.g << 8 | rgbColor.b).toString(16).slice(1)
}

export const rgbColorToGrayscale = (rgbColor: RgbColor): RgbColor => {
  const r = rgbColor.r * .3
  const g = rgbColor.g * .59
  const b = rgbColor.b * .11

  let gray = Math.round(r + g + b)

  gray = Math.min(Math.max(gray, 0), 255)

  return {
    r: gray,
    g: gray,
    b: gray,
  }
}

export const hexColorToGrayscale = (hexColor: HexColor): HexColor => {
  const rgbColor = hexColorToRgb(hexColor)
  const rgbGray = rgbColorToGrayscale(rgbColor)
  const hexGray = rgbColorToHex(rgbGray)

  return hexGray
}

export const darkenRgbColor = (rgbColor: RgbColor, factor: number): RgbColor => {
  return {
    r: Math.min(Math.max(Math.round(rgbColor.r * factor), 0), 255),
    g: Math.min(Math.max(Math.round(rgbColor.g * factor), 0), 255),
    b: Math.min(Math.max(Math.round(rgbColor.b * factor), 0), 255),
  }
}

export const darkenHexColor = (hexColor: HexColor, factor: number): HexColor => {
  const rgbColor = hexColorToRgb(hexColor)
  const darkenedRgb = darkenRgbColor(rgbColor, factor)
  const darkenedHex = rgbColorToHex(darkenedRgb)

  return darkenedHex
}
