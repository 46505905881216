import { useEffect, useMemo, useRef } from 'react'
import { PivotalExecutioner } from '../utilities'

// NOTE: make sure that this hook gets reinitialize for every object. For example,
// if you first initialize this hook for project number 17, do not allow to continue
// using the same hook for project 20. Otherwise you'll encounter unwanted
// sideeffects.

// In practice, if you have a component `<ProjectForm project={project} />`, which
// uses `useInputFieldAutoUpdate` for a field of `project`, add a `key` to the
// component, even if it's not a list. So use
// `<ProjectForm key={project.id} project={project} />` instead. This will make sure
// that the component gets recreated whenever the `project` changes. And so also the
// hook will get reinitialized.
function useInputFieldAutoUpdate({
  inputFieldValue,
  storedValueAsInputFieldValue,
  mutation,
  buildMutationVariablesWithNewValue,
}) {
  const pivotalExecutioner = useRef(new PivotalExecutioner({
    waitFor: 2_500,
    dontWaitLongerThan: 10_000,
  }))
  const outOfSync = useMemo(
    () => inputFieldValue !== storedValueAsInputFieldValue,
    [inputFieldValue, storedValueAsInputFieldValue]
  )

  useEffect(() => {
    if (outOfSync) {
      const updateValue = () => {
        mutation({
          variables: buildMutationVariablesWithNewValue(inputFieldValue),
          onError: (error) => {
            if (error.networkError) {
              pivotalExecutioner.current.execute(updateValue, { waitFor: 10_000 })
            }
          },
        })
      }
      pivotalExecutioner.current.execute(updateValue)
    } else {
      pivotalExecutioner.current.cancel()
    }
  }, [
    inputFieldValue,
    storedValueAsInputFieldValue,
    outOfSync,
    mutation,
    buildMutationVariablesWithNewValue,
  ])

  return {
    outOfSync,
  }
}

export default useInputFieldAutoUpdate
