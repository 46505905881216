import React from 'react'
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
} from '@apollo/client'
import Router from './Router'

const traxApiSignInUrl = process.env.REACT_APP_TRAX_API_SIGN_IN_URL
const traxApiDemoSignInUrl = process.env.REACT_APP_TRAX_API_DEMO_SIGN_IN_URL
const traxApiSignOutUrl = process.env.REACT_APP_TRAX_API_SIGN_OUT_URL
const traxApiGraphQlApi = process.env.REACT_APP_TRAX_API_GRAPH_QL_URL

const cache = new InMemoryCache({
  typePolicies: {
    Color: {
      keyFields: ['hexCode'],
    },
    Project: {
      fields: {
        colorPalette: {
          merge: false,
        },
      },
    },
    Query: {
      fields: {
        sessions: {
          merge: (existing, incoming) => incoming,
        },
        getImages: {
          merge: (existing, incoming) => incoming,
        },
      },
    },
  },
})

const apolloClient = new ApolloClient({
  link: createHttpLink({
    uri: traxApiGraphQlApi,
    credentials: 'include',
  }),
  cache,
})

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <Router
        traxApiSignInUrl={traxApiSignInUrl}
        traxApiSignOutUrl={traxApiSignOutUrl}
        traxApiDemoSignInUrl={traxApiDemoSignInUrl}
      />
    </ApolloProvider>
  )
}

export default App
