import { useState, useMemo } from 'react'
import ProjectLinkColorPalette from './ProjectLink/ProjectLinkColorPalette'
import { Id, ProjectSelectorProject, Streak, ImagesIndex, Image, Settings } from '../types'
import './ProjectLink.css'
import styles from './ProjectLink.module.scss'
import { darkenHexColor } from '../color_utils'
import classNames from 'classnames'
import useExtendedInteractionEvents from '../hooks/useExtendedInteractionEvents'

const renderStreak = (visibilityLevel: number, streak: Streak | null, neglectedSinceDays: number | null) => {
  if (!streak && !neglectedSinceDays) {
    return <div className={styles.noStreakSpacer} />
  }

  let className = styles.streakContainer

  if (visibilityLevel === 1) {
    className += ' ' + styles.streakContainerRecordSetting
  }

  return (
    <>
      <div className={className}>
        {streak && (
          <>
            {streak.length === 0 ? '' : streak.length}
            {streak.missed > 0 && (
              <span className={visibilityLevel === 1 ? styles.streakMissed : styles.streakMissedDarker}> - {streak.missed}</span>
            )}
          </>
        )}
      </div>
      {neglectedSinceDays && (
        <div className={className}>
          <span className={styles.neglectedSinceDays}>{-neglectedSinceDays}</span>
        </div>
      )}
    </>
  )
}

const activityScoreTitle = (project: ProjectSelectorProject): string | undefined => {
  if (project.currentStreak) {
    if (project.currentStreak.nextSessionDueInDays === 0) {
      return `Complete a session for this project today to make it a ${project.currentStreak.length + 1}-day-streak!`
    } else {
      return `You're on a ${project.currentStreak.length}-day-streak for this project.`
    }
  } else {
    if (project.activityToday === 0) {
      return 'You did not yet complete a session for this project today.'
    }
  }
}

const markers = (project: ProjectSelectorProject) => {
  if (project.currentStreak) {
    if (project.currentStreak.nextSessionDueInDays === 0) {
      return <div className="ProjectLink-target-marker high-vis" />
    }
  } else {
    if (project.activityToday === 0) {
      return <div className="ProjectLink-target-marker low-vis" />
    }
  }

  return null
}

function ProgressBar({ progress, minutesLeft }: { progress: number; minutesLeft: number; }) {
  const beyond = progress >= 1
  const barLength = beyond ? Math.min(100, Math.round((progress - 1) * 100)) : Math.round((1 - progress) * 100)

  return (
    <div
      className="ProjectLink-ProgressBar"
      style={{
        // width: `${barLength}%`,
        backgroundColor: beyond ? 'rgba(72, 0, 0, 0.75)' : undefined,
        height: `${barLength}%`,
        color: beyond ? 'rgb(217, 50, 50)' : undefined,
      }}
    >
      {[...Array(10)].map((_, index) => <span key={index} style={{ marginTop: 10 }}>{Math.round(minutesLeft)} min</span>)}
    </div>
  )
}

function ProjectLink(
  {
    selected,
    project,
    onClick,
    onLongPress,
    visibilityLevel,
    mark,
    streak,
    neglectedSinceDays,
    backgroundImage,
    showColorPalette,
    backgroundImageColored,
    progress,
  }:
  {
    selected: boolean;
    project: ProjectSelectorProject;
    onClick: () => void;
    onLongPress: (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => void;
    visibilityLevel: number;
    mark?: boolean;
    streak: Streak | null;
    neglectedSinceDays: number | null;
    backgroundImage: Image | null;
    showColorPalette: boolean;
    backgroundImageColored: boolean;
    progress: number | null;
  },
) {
  // const longPressButtonAttributes = useExtendedInteractionEvents<HTMLElement>({ onClick, onLongPress })

  return (
    <div
      key={project.id}
      // {...longPressButtonAttributes}
      onClick={onClick}
      className="ProjectLink-container"
    >
      {backgroundImage && (
        <div className="ProjectLink-background-container">
          <div className={classNames('ProjectLink-background-image-container', { 'ProjectLink-background-image-container-grayscale': !backgroundImageColored })}>
            <img src={backgroundImage.url} alt="" />
          </div>
          {!backgroundImageColored && (
            <div
              className="ProjectLink-background-image-overlay"
              style={{ background: `radial-gradient(circle, rgba(0, 0, 0, 0.${Math.round(1 + (5 * (1 - visibilityLevel)))}) 10%, rgb(0, 0, 0) 100%)` }}
            />
          )}
        </div>
      )}
      {progress !== null && (
        <ProgressBar progress={progress} minutesLeft={project.minutesPerDay ? project.minutesPerDay - project.activityToday : 0} />
      )}
      <div
        className={classNames('ProjectLink-content-container', {
          'ProjectLink-content-container-background-color': !backgroundImage,
          'selected': selected,
        })}
      >
        <div className="ProjectLink-bottom-bar"></div>
        <div className="ProjectLink-activity-score" title={activityScoreTitle(project)}>
          {mark && (
            <div className="ProjectLink-markers">
              {markers(project)}
            </div>
          )}
          {renderStreak(visibilityLevel, streak, neglectedSinceDays)}
        </div>
        <div className="ProjectLink-ColorPalette">
          {showColorPalette && project.colorPalette.length > 0 && (
            <ProjectLinkColorPalette colorPalette={project.colorPalette} visibilityLevel={1} />
          )}
        </div>
        <div className="ProjectLink-label" style={{ color: darkenHexColor('#EEEEEE', 0.2 + 0.8 * visibilityLevel) }}>
          {project.name}
        </div>
      </div>
    </div>
  )
}

const visibilityForGlobalProgress = (
  { project, globalActivityInMinutes, settings }:
  {
    project: ProjectSelectorProject;
    globalActivityInMinutes: number;
    settings: Settings;
  },
) => {
  if (!project.countsTowardsGlobalMinutesPerDay || settings.minutesPerDay === null || globalActivityInMinutes < settings.minutesPerDay) return 1

  const relativeProgress = globalActivityInMinutes / settings.minutesPerDay

  return Math.max(0.9 - (relativeProgress - 1) * 0.9 / 0.3333, 0.4)
}

const determineVisibilityLevel = (
  { project, focusedProjectsProgress, hovered, globalActivityInMinutes, settings }:
  {
    project: ProjectSelectorProject;
    focusedProjectsProgress: number;
    hovered: boolean;
    globalActivityInMinutes: number;
    settings: Settings;
  },
) => {
  if (hovered) return 1

  if (project.isTimeOff) return 0
  if (!project.frequency || project.isSnoozed) {
    return 0
  }

  const globalProgressReductionFactor = visibilityForGlobalProgress({ project, globalActivityInMinutes, settings })
  const actualValue = (!project.currentStreak || project.currentStreak.numberOfDaysSinceLastSession === null)
    ? 1
    : project.currentStreak.numberOfDaysSinceLastSession / project.frequency
  const focusDarkeningInterval = 0.7
  const focusReduction = project.focus ? 1 : (1 - focusDarkeningInterval) + focusDarkeningInterval * focusedProjectsProgress

  return Math.min(1, actualValue) * focusReduction * globalProgressReductionFactor
}

const determineStreakData = (
  { hovered, streak, selected, project, focusedProjectsProgress }:
  {
    hovered: boolean;
    streak: Streak | null;
    selected: boolean;
    project: ProjectSelectorProject;
    focusedProjectsProgress: number;
  },
) => {
  const streakActivated = project.streak

  if (!streak || !streakActivated || project.isTimeOff || project.isSnoozed) return null
  if (selected || hovered) return streak
  if (focusedProjectsProgress < 1) return null
  if (streak.nextSessionDueInDays === 0) return streak

  return null
}

const determineNeglectedSinceDays = (
  { project, highestNeglectionFactor, fullVisibility }:
  {
    project: ProjectSelectorProject;
    highestNeglectionFactor: number | null;
    fullVisibility: boolean;
  },
) => {
  if (project.isTimeOff || !project.neglectionFactor || !highestNeglectionFactor || !project.currentStreak || !fullVisibility) return null
  if (project.neglectionFactor < highestNeglectionFactor || project.neglectionFactor <= 5) return null

  return project.currentStreak.numberOfDaysSinceLastSession
}

function ProjectLinkWrapper(
  {
    selected,
    project,
    onClick,
    onLongPress,
    highestNeglectionFactor,
    focusedProjectsProgress,
    imagesIndex,
    backgroundImageId,
    settings,
    globalActivityInMinutes,
  }:
  {
    selected: boolean;
    project: ProjectSelectorProject;
    onClick: () => void;
    onLongPress: (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => void;
    highestNeglectionFactor: number | null;
    focusedProjectsProgress: number;
    imagesIndex: ImagesIndex;
    backgroundImageId: Id | null;
    settings: Settings;
    globalActivityInMinutes: number;
  },
) {
  const [hovered, setHovered] = useState(false)
  const visibilityLevel = determineVisibilityLevel({ project, focusedProjectsProgress, hovered, globalActivityInMinutes, settings })
  const fullVisibility = visibilityLevel === 1
  const backgroundImage = useMemo(() => {
    if (!project.buttonBackgroundImageId) return null

    if (fullVisibility || selected) {
      return imagesIndex[project.buttonBackgroundImageId]
    }

    return null
  }, [fullVisibility, selected, imagesIndex, project.buttonBackgroundImageId])

  return (
    <div
      className="ProjectLinkWrapper"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <ProjectLink
        selected={selected}
        project={project}
        onClick={onClick}
        onLongPress={onLongPress}
        visibilityLevel={visibilityLevel}
        streak={determineStreakData({ hovered, streak: project.currentStreak, selected, project, focusedProjectsProgress })}
        neglectedSinceDays={determineNeglectedSinceDays({ highestNeglectionFactor, project, fullVisibility })}
        backgroundImage={backgroundImage}
        backgroundImageColored={hovered || (selected && fullVisibility && !project.wallpaperImageId && !backgroundImageId)}
        showColorPalette={hovered || (selected && !project.wallpaperImageId && !backgroundImageId)}
        progress={(project.minutesPerDay && (selected || (hovered && project.dueToday))) ? project.activityToday / project.minutesPerDay : null}
      />
    </div>
  )
}

export default ProjectLinkWrapper
