import { useState, useCallback } from 'react'
import {
  Image as ImageType,
  ImageDisplayOption,
  Project,
  GetImagesResult,
  DeleteImageResult,
  UpdateImageResult,
  ImageSize,
} from './types'
import { useMutation } from '@apollo/client'
import { FETCH_IMAGES_QUERY, DELETE_IMAGE_MUTATION, UPDATE_IMAGE_MUTATION, UPDATE_PROJECT_MUTATION } from './queries'
import Image from './Image'
import { Button } from './clickables'
import { TextInput, LabeledInputBlock } from './form'
import useInputFieldAutoUpdate from './hooks/useInputFieldAutoUpdate'
import { updateProjectCache, buildUpdateProjectParams } from './utilities'
import useImageProjectLinkers from './hooks/useImageProjectLinkers'
import './ImageEditor.css'

function labelForImageDisplayOption(imageDisplayOption: ImageDisplayOption) {
  switch (imageDisplayOption) {
  case ImageDisplayOption.CoverAvailableSpace:
    return 'Cover all'
  case ImageDisplayOption.CoverAvailableSpaceBlurred:
    return 'Cover all (blurred)'
  case ImageDisplayOption.DownscaleToFitWithBlurredBackground:
    return 'Fit (fill gaps with blur)'
  case ImageDisplayOption.DownscaleToFitWithBlurredBackgroundWithMargin:
    return 'Fit within margin (fill gaps with blur)'
  case ImageDisplayOption.DownscaleToFitWithBackgroundColor:
    return 'Fit (fill gaps with color)'
  case ImageDisplayOption.DownscaleToFitWithBackgroundColorWithMargin:
    return 'Fit within margin (fill gaps with color)'
  }
}

function ImageEditor(
  { image, project, beforeDelete, beforeUnlink }:
  {
    image: ImageType;
    project: Project;
    beforeDelete?: () => void;
    beforeUnlink?: () => void;
  },
) {
  const isWallpaper = project.wallpaperImageId === image.id
  const isButtonBackground = project.buttonBackgroundImageId === image.id
  const [tags, setTags] = useState(image.tags)
  const { unlinkImageFromProject, loading: unlinkingImage } = useImageProjectLinkers({ projectId: project.id, imageId: image.id })
  const [deleteImage, { loading: deletingImage }] = useMutation<DeleteImageResult>(
    DELETE_IMAGE_MUTATION,
    {
      refetchQueries: ['FetchImages', 'FetchImageIdsForProject'],
    },
  )
  const onClickDeleteImage = () => {
    const choiceConfirmed = window.confirm('Are you sure?')

    if (!choiceConfirmed) return

    if (beforeDelete) beforeDelete()
    deleteImage({ variables: { id: image.id } })
  }
  const onClickUnlink = () => {
    if (beforeUnlink) beforeUnlink()
    unlinkImageFromProject()
  }
  const [updateImage] = useMutation<UpdateImageResult>(UPDATE_IMAGE_MUTATION, {
    update(cache, { data: mutationData }) {
      if (!mutationData || !mutationData.updateImage.image) return

      const cachedFetchedImages = cache.readQuery<GetImagesResult>({ query: FETCH_IMAGES_QUERY })

      if (!cachedFetchedImages) return

      const updatedImages = [...cachedFetchedImages.getImages]
      const imageIndex = updatedImages.findIndex((updatedImage) => updatedImage.id === image.id)
      updatedImages[imageIndex] = mutationData.updateImage.image

      cache.writeQuery<GetImagesResult>({
        query: FETCH_IMAGES_QUERY,
        data: {
          getImages: updatedImages,
        },
      })
    },
  })
  const onChangeDisplayOption = (event: React.FormEvent<HTMLSelectElement>) => {
    const value = event.currentTarget.value
    const newDisplayOption = value as ImageDisplayOption

    updateImage({
      variables: {
        ...image,
        display: newDisplayOption,
      },
    })
  }
  const updateImageMutationVariablesForNewTags = useCallback((newTags) => {
    return {
      ...image,
      tags: newTags,
    }
  }, [image])
  const [updateProject, { loading: updatingImage }] = useMutation(
    UPDATE_PROJECT_MUTATION,
    {
      update(cache, data) { updateProjectCache(cache, data) },
    },
  )
  const onClickUseAsWallpaper = async() => {
    const variables = buildUpdateProjectParams(project, { wallpaperImageId: image.id })

    await updateProject({ variables })
  }
  const onClickStopUsingAsWallpaper = async() => {
    const variables = buildUpdateProjectParams(project, { wallpaperImageId: null })

    await updateProject({ variables })
  }
  const onClickUseAsButtonBackground = async() => {
    const variables = buildUpdateProjectParams(project, { buttonBackgroundImageId: image.id })

    await updateProject({ variables })
  }
  const onClickStopUsingAsButtonBackground = async() => {
    const variables = buildUpdateProjectParams(project, { buttonBackgroundImageId: null })

    await updateProject({ variables })
  }
  const { outOfSync } = useInputFieldAutoUpdate({
    inputFieldValue: tags.split(',').map(tag => tag.trim()).join(', '),
    storedValueAsInputFieldValue: image.tags,
    mutation: updateImage,
    buildMutationVariablesWithNewValue: updateImageMutationVariablesForNewTags,
  })

  return (
    <div className="ImageEditor-image-row-container">
      <div className="ImageEditor-image-container">
        <Image image={image} size={ImageSize.Xs} />
      </div>
      <div className="ImageEditor-actions-container">
        <div>
          <LabeledInputBlock
            id={`image-${image.id}-display`}
            text="Display"
            description="Select the best way to display this image"
            optional={false}
          >
            <select
              value={image.display}
              onChange={onChangeDisplayOption}
            >
              {Object.values(ImageDisplayOption).map((optionValue) => (
                <option key={optionValue} value={optionValue}>{labelForImageDisplayOption(optionValue)}</option>
              ))}
            </select>
          </LabeledInputBlock>
        </div>
        <div>
          <LabeledInputBlock
            id={`image-${image.id}-tags`}
            text="Tags"
            description="A comma-separated list of tags"
            optional
          >
            <TextInput
              value={tags}
              onChange={setTags}
              outOfSync={outOfSync}
            />
          </LabeledInputBlock>
        </div>
        <div>
          <div>
            {isWallpaper ? (
              <Button
                onClick={onClickStopUsingAsWallpaper}
                disabled={updatingImage}
              >
                Stop using as wallpaper
              </Button>
            ) : (
              <Button
                onClick={onClickUseAsWallpaper}
                disabled={updatingImage}
              >
                Use as wallpaper
              </Button>
            )}
          </div>
          <div>
            {isButtonBackground ? (
              <Button
                onClick={onClickStopUsingAsButtonBackground}
                disabled={updatingImage}
              >
                Stop using as button background image
              </Button>
            ) : (
              <Button
                onClick={onClickUseAsButtonBackground}
                disabled={updatingImage}
              >
                Use as button background image
              </Button>
            )}
          </div>
          <div>
            <Button
              onClick={onClickUnlink}
              disabled={unlinkingImage}
            >
              Unlink from this project
            </Button>
          </div>
          <div>
            <Button
              onClick={onClickDeleteImage}
              disabled={deletingImage}
            >
              Delete and unlink from all projects
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageEditor
