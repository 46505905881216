import { useState, useEffect, useCallback } from 'react'
import { useQuery } from '@apollo/client'
import ProjectLink from './Header/ProjectLink'
import { FETCH_PROJECTS_QUERY } from './queries'
import triangleSvg from './triangle.svg'
import { useInterval } from './utilities'
import { calculateProjectNeglectionFactor, determineIsTimeOff, dateIsUpcoming } from './utilities2'
import ProjectQuickMenu from './ProjectQuickMenu'
import { DateTime } from 'luxon'
import './ProjectSelector.css'

const sortProjectsAlphabetically = arrayOfProjects => {
  return arrayOfProjects.sort((projectA, projectB) => projectA.name.localeCompare(projectB.name))
}

function ProjectSelector({ setProjectsDict, projectsDict, appState, updateAppState, activityStatistics, settings, imagesIndex, backgroundImageId, globalActivityInMinutes }) {
  const [showMore, setShowMore] = useState(false)
  const [decoratedProjects, setDecoratedProjects] = useState(null)
  const [activeProjects, setActiveProjects] = useState(null)
  const [inactiveProjects, setInactiveProjects] = useState(null)
  const [highestNeglectionFactor, setHighestNeglectionFactor] = useState(null)
  const [focusedProjectsProgress, setFocusedProjectsProgress] = useState(1)
  const [showProjectMenuForProjectId, setShowProjectMenuForProjectId] = useState(null)

  const { loading, error, data } = useQuery(FETCH_PROJECTS_QUERY)

  const decorateProjects = useCallback((projects, activityStatistics) => {
    const t0 = performance.now()

    let highestNeglectionFactorTemp = null
    let focusedProjectsMinutesTarget = 0
    let focusedProjectsMinutesAccomplished = 0
    let focusedProjectWithIndefiniteMinutesPerDayExists = false

    const decoratedProjects = projects.map(project => {
      const isSnoozed = project.lowestVisibilityUntil && dateIsUpcoming(project.lowestVisibilityUntil, DateTime.now(), settings.timeOfDaybreak)
      const isTimeOff = determineIsTimeOff(settings, project)
      const activityToday = activityStatistics &&
        activityStatistics.activityForProjectOnDay(
          project.id,
          activityStatistics.dateCode(DateTime.local()),
        )
      const currentStreak = (project.frequency && project.minutesPerDay !== null && activityStatistics)
        ? activityStatistics.streak({ project, atDateTime: DateTime.local() })
        : null

      const neglectionFactor = calculateProjectNeglectionFactor({ project, streak: currentStreak })

      if (project.active && (!highestNeglectionFactorTemp || neglectionFactor > highestNeglectionFactorTemp)) {
        highestNeglectionFactorTemp = neglectionFactor
      }

      const dueToday = project.minutesPerDay && !isSnoozed && currentStreak && currentStreak.nextSessionDueInDays === 0 && !isTimeOff
      if (project.focus) {
        if (dueToday) {
          focusedProjectsMinutesTarget += project.minutesPerDay
          focusedProjectsMinutesAccomplished += Math.min(project.minutesPerDay, activityToday)
        } else if (!project.minutesPerDay) {
          focusedProjectWithIndefiniteMinutesPerDayExists = true
        }
      }

      return {
        ...project,
        activityToday,
        dueToday,
        currentStreak,
        neglectionFactor,
        isTimeOff,
        isSnoozed,
      }
    })

    setHighestNeglectionFactor(highestNeglectionFactorTemp)

    let progress = 1
    if (focusedProjectsMinutesTarget > 0) {
      progress = focusedProjectsMinutesAccomplished / focusedProjectsMinutesTarget
    }
    if (focusedProjectWithIndefiniteMinutesPerDayExists) {
      progress *= 0
    }
    setFocusedProjectsProgress(progress)

    const t1 = performance.now()
    console.log(`Decorated ${projects.length} projects for header after ${t1 - t0} ms`)

    return decoratedProjects
  }, [settings])

  useEffect(() => {
    if (!data) return

    setDecoratedProjects(decorateProjects(data.projects, activityStatistics))
  }, [data, activityStatistics, decorateProjects])

  useInterval(() => {
    if (!data) return

    setDecoratedProjects(decorateProjects(data.projects, activityStatistics))
  }, 600_000)

  useEffect(() => {
    if (!decoratedProjects) return

    const projectsDict = {}

    decoratedProjects.forEach(project => {
      projectsDict[project.id] = project
    })

    setProjectsDict(projectsDict)
  }, [decoratedProjects, setProjectsDict])

  useEffect(() => {
    if (!decoratedProjects) return

    const activeProjects = decoratedProjects.filter(project => project.active)
    const inactiveProjects = decoratedProjects.filter(project => !project.active)

    setActiveProjects(sortProjectsAlphabetically(activeProjects))
    setInactiveProjects(sortProjectsAlphabetically(inactiveProjects))
  }, [decoratedProjects])

  const selectProject = projectId => {
    updateAppState({ projectId })
  }

  const projectIsSelected = project => {
    return appState && project.id === appState.projectId
  }

  const openProjectMenu = (event, projectId) => {
    setShowProjectMenuForProjectId({ projectId, coordinates: { x: event.clientX, y: event.clientY }})
  }

  if (loading || !activeProjects || !inactiveProjects) return null
  if (error) return (<p>Error :(</p>)

  return (
    <div className="ProjectSelector-container">
      {activeProjects.map(project => (
        <ProjectLink
          key={project.id}
          project={project}
          selected={projectIsSelected(project)}
          onClick={() => selectProject(project.id)}
          onLongPress={(event) => openProjectMenu(event, project.id)}
          highestNeglectionFactor={highestNeglectionFactor}
          focusedProjectsProgress={focusedProjectsProgress}
          imagesIndex={imagesIndex}
          settings={settings}
          backgroundImageId={backgroundImageId}
          globalActivityInMinutes={globalActivityInMinutes}
        />
      ))}
      {inactiveProjects.length > 0 && (
        <>
          <div
            onClick={() => setShowMore(!showMore)}
            className={`ProjectSelector-show-inactive-projects-button${showMore ? ' active' : ''}`}
          >
            <img src={triangleSvg} alt="Pointer" />
          </div>
          {showMore && (inactiveProjects.map(project => (
            <ProjectLink
              key={project.id}
              project={project}
              selected={projectIsSelected(project)}
              onClick={selectProject}
              activityStatistics={activityStatistics}
              highestNeglectionFactor={highestNeglectionFactor}
              focusedProjectsProgress={focusedProjectsProgress}
              imagesIndex={imagesIndex}
              settings={settings}
              globalActivityInMinutes={globalActivityInMinutes}
            />
          )))}
        </>
      )}
      {showProjectMenuForProjectId && projectsDict[showProjectMenuForProjectId.projectId] && (
        <ProjectQuickMenu
          project={projectsDict[showProjectMenuForProjectId.projectId]}
          position={showProjectMenuForProjectId.coordinates}
          onActionCompleted={() => setShowProjectMenuForProjectId(null) }
          onClickClose={() => setShowProjectMenuForProjectId(null) }
          settings={settings}
        />
      )}
    </div>
  )
}

export default ProjectSelector
