import React from 'react'
import HtmlTextArea from './HtmlTextArea'
import classNames from 'classnames'
import './MultilineTextInput.css'

function MultilineTextInput(
  {
    value,
    onChange,
    inputElementRef,
    id,
    rows,
    placeholder,
    fullWidth,
    fullHeight,
    outOfSync,
    disableResizing,
  }:
  {
    value: string;
    onChange: (newValue: string) => void;
    inputElementRef?: React.RefObject<HTMLTextAreaElement>;
    id?: string;
    rows?: number;
    placeholder?: string;
    fullWidth?: boolean;
    fullHeight?: boolean;
    outOfSync?: boolean;
    disableResizing?: boolean;
  },
) {
  return (
    <HtmlTextArea
      value={value}
      onChange={(event) => onChange(event.currentTarget.value)}
      inputElementRef={inputElementRef}
      id={id}
      rows={rows}
      placeholder={placeholder}
      className={classNames(
        'MultilineTextInput',
        {
          'MultilineTextInput-full-width': fullWidth,
          'MultilineTextInput-full-height': fullHeight,
          'input-out-of-sync': outOfSync,
          'MultilineTextInput-no-resizing': disableResizing,
        },
      )}
    />
  )
}

export default MultilineTextInput
