import { useState, useRef, useCallback } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { DateTime } from 'luxon'
import ReactDragListView from 'react-drag-listview/lib/index.js'
import {
  FETCH_TODOS_QUERY,
  CREATE_TODO_MUTATION,
  UPDATE_TODO_MUTATION,
  SET_TODO_PRIORITY_MUTATION,
  COMPLETE_TODO_MUTATION,
  UNCOMPLETE_TODO_MUTATION,
  DELETE_TODO_MUTATION,
} from './queries'
import useInputFieldAutoUpdate from './hooks/useInputFieldAutoUpdate'
import { Button } from './clickables'
import './Todos.css'

function TodoForm({ projectId }) {
  const descriptionInput = useRef(null)
  const [createTodo] = useMutation(CREATE_TODO_MUTATION)

  const onFormSubmit = event => {
    event.preventDefault()

    createTodo({
      variables: {
        projectId,
        description: descriptionInput.current.value,
        notes: '',
        priority: 1,
      },
      update(cache, { data: { createTodo: { todo } } }) {
        const { todos, completedTodos } = cache.readQuery({
          query: FETCH_TODOS_QUERY,
          variables: { projectId },
        })

        const newTodos = [todo, ...todos]

        cache.writeQuery({
          query: FETCH_TODOS_QUERY,
          variables: { projectId },
          data: { todos: newTodos, completedTodos },
        })

        descriptionInput.current.value = ''
      },
    })
  }

  return (
    <div className="TodoForm-container">
      <form onSubmit={onFormSubmit}>
        <div className="TodoForm-inline-form">
          <input
            ref={descriptionInput}
            type="text"
            className="TodoForm-description-input"
            placeholder="New todo"
          />
          <Button title="Add a new todo" submit>Add Todo</Button>
        </div>
      </form>
    </div>
  )
}

// NOTE: only use this component with a `key`.
function Todo({ todo, children }) {
  const [notes, setNotes] = useState(todo.notes || '')
  const [description, setDescription] = useState(todo.description || '')
  const [updateTodo] = useMutation(UPDATE_TODO_MUTATION)
  const [completeTodo] = useMutation(COMPLETE_TODO_MUTATION)
  const [uncompleteTodo] = useMutation(UNCOMPLETE_TODO_MUTATION)
  const [deleteTodo] = useMutation(DELETE_TODO_MUTATION, {
    update(cache, { data: { deleteTodo } }) {
      const { todos, completedTodos } = cache.readQuery({
        query: FETCH_TODOS_QUERY,
        variables: { projectId },
      })

      const newTodos = todos.filter(todoX => todoX.id !== id)
      const newCompletedTodos = completedTodos.filter(todoX => todoX.id !== id)

      cache.writeQuery({
        query: FETCH_TODOS_QUERY,
        variables: { projectId },
        data: {
          todos: newTodos,
          completedTodos: newCompletedTodos,
        },
      })
    },
  })

  const { id, projectId, completedAt } = todo

  const toggleCompleted = () => {
    if (todo.completedAt) {
      uncompleteTodo({
        variables: { id },
        update(cache, { data: { uncompleteTodo: { todo } } }) {
          const { todos, completedTodos } = cache.readQuery({
            query: FETCH_TODOS_QUERY,
            variables: { projectId },
          })

          const newTodos = [todo, ...todos]
          const newCompletedTodos = completedTodos.filter(todo => todo.id !== id)

          cache.writeQuery({
            query: FETCH_TODOS_QUERY,
            variables: { projectId },
            data: {
              todos: newTodos,
              completedTodos: newCompletedTodos,
            },
          })
        },
      })
    } else {
      completeTodo({
        variables: { id, completedAt: DateTime.utc().toISO() },
        update(cache, { data: { completeTodo: { todo } } }) {
          const { todos, completedTodos } = cache.readQuery({
            query: FETCH_TODOS_QUERY,
            variables: { projectId },
          })

          const newTodos = todos.filter(todo => todo.id !== id)
          const newCompletedTodos = [todo, ...completedTodos]

          cache.writeQuery({
            query: FETCH_TODOS_QUERY,
            variables: { projectId },
            data: {
              todos: newTodos,
              completedTodos: newCompletedTodos,
            },
          })
        },
      })
    }
  }

  const clickDelete = () => {
    const choiceConfirmed = window.confirm(`Do you really want to delete todo "${todo.description}"?`)

    if (!choiceConfirmed) return

    deleteTodo({ variables: { id: todo.id } })
  }

  const updateTodoMutationVariablesForNewDescription = useCallback((newDescription) => {
    return {
      ...todo,
      notes,
      description: newDescription,
    }
  }, [todo, notes])
  const { outOfSync: descriptionOutOfSync } = useInputFieldAutoUpdate({
    inputFieldValue: description,
    storedValueAsInputFieldValue: todo.description || '',
    mutation: updateTodo,
    buildMutationVariablesWithNewValue: updateTodoMutationVariablesForNewDescription,
  })

  const updateTodoMutationVariablesForNewNotes = useCallback((newNotes) => {
    return {
      ...todo,
      description,
      notes: newNotes,
    }
  }, [todo, description])
  const { outOfSync: notesOutOfSync } = useInputFieldAutoUpdate({
    inputFieldValue: notes,
    storedValueAsInputFieldValue: todo.notes || '',
    mutation: updateTodo,
    buildMutationVariablesWithNewValue: updateTodoMutationVariablesForNewNotes,
  })

  return (
    <li className="Todo-container">
      <div className="Todo-main">
        <div>
          <input
            type="checkbox"
            value="completed"
            onChange={() => toggleCompleted(todo.id)}
            checked={completedAt != null}
            title="Mark this todo as completed"
          />
        </div>
        <div className="Todo-inputs-container">
          <input
            type="text"
            value={description}
            onChange={(event) => setDescription(event.currentTarget.value)}
            className={`Todo-description-input ${descriptionOutOfSync ? 'input-out-of-sync' : ''}`}
          />
          <textarea
            type="text"
            value={notes}
            onChange={(event) => setNotes(event.currentTarget.value)}
            placeholder="Notes"
            className={`Todo-notes-input ${notesOutOfSync ? 'input-out-of-sync' : ''}`}
          />
        </div>
      </div>
      <div className="Todo-buttons">
        {children}
        <Button onClick={clickDelete}>Delete</Button>
      </div>
    </li>
  )
}

function Todos({ appState }) {
  const [showCompletedTodos, setShowCompletedTodos] = useState(false)
  const { loading, error, data } = useQuery(FETCH_TODOS_QUERY, {
    variables: { projectId: appState.projectId },
  })
  const todos = data ? data.todos : null
  const completedTodos = data ? data.completedTodos : null
  const [setTodoPriority] = useMutation(SET_TODO_PRIORITY_MUTATION)

  const moveToBottom = todo => {
    const fromIndex = todos.findIndex(todoX => todoX.id === todo.id)
    const toIndex = todos.length - 1

    setTodoPriority({
      variables: {
        id: todo.id,
        priority: toIndex + 1,
      },
      update(cache, { data: { setTodoPriority: { todo } } }) {
        const { todos, completedTodos } = cache.readQuery({
          query: FETCH_TODOS_QUERY,
          variables: { projectId: todo.projectId },
        })

        const newTodos = [...todos]
        const draggedTodo = newTodos.splice(fromIndex, 1)[0]
        newTodos.splice(toIndex, 0, draggedTodo)

        cache.writeQuery({
          query: FETCH_TODOS_QUERY,
          variables: { projectId: todo.projectId },
          data: { todos: newTodos, completedTodos },
        })
      },
    })
  }

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const variables = {
        id: todos[fromIndex].id,
        priority: toIndex + 1,
      }

      setTodoPriority({
        variables,
        update(cache, { data: { setTodoPriority: { todo } } }) {
          const { todos, completedTodos } = cache.readQuery({
            query: FETCH_TODOS_QUERY,
            variables: { projectId: todo.projectId },
          })

          const newTodos = [...todos]
          const draggedTodo = newTodos.splice(fromIndex, 1)[0]
          newTodos.splice(toIndex, 0, draggedTodo)

          cache.writeQuery({
            query: FETCH_TODOS_QUERY,
            variables: { projectId: todo.projectId },
            data: { todos: newTodos, completedTodos },
          })
        },
      })
    },
    nodeSelector: 'li',
    handleSelector: 'button.drag',
  }

  if (loading || error) return null

  return (
    <div className="Todos">
      <TodoForm projectId={appState.projectId} />
      <div className="Todos-todos-list">
        <ReactDragListView {...dragProps}>
          <ol>
            {todos.map(todo => (
              <Todo key={todo.id} todo={todo}>
                <Button drag>Drag</Button>
                <Button onClick={() => moveToBottom(todo)} title="Move to bottom">Bottom</Button>
              </Todo>
            ))}
          </ol>
        </ReactDragListView>
        <div className="Todos-show-completed-button-container">
          <Button onClick={() => setShowCompletedTodos(!showCompletedTodos)}>Show completed todos</Button>
        </div>
        {showCompletedTodos && (
          <ol>
            {completedTodos.map(todo => (
              <Todo key={todo.id} todo={todo} />
            ))}
          </ol>
        )}
      </div>
    </div>
  )
}

export default Todos
