import { useEffect, useRef } from 'react'
import { DateTime } from 'luxon'
import { FETCH_PROJECTS_QUERY } from './queries'

export const HOURS_OFFSET = 4
export const RELATIVE_ACTIVITY_INTERVAL = 14

export const msInMSM = inMilliseconds => {
  const [hours, millisecondsLeftAfterHours] = divmod(inMilliseconds, 3_600_000)
  const [minutes, millisecondsLeftAfterMinutes] = divmod(millisecondsLeftAfterHours, 60_000)
  const [seconds, milliseconds] = divmod(millisecondsLeftAfterMinutes, 1_000)

  return [hours, minutes, seconds, milliseconds]
}

export const divmod = (number, divisor) => {
  const quotient = Math.floor(number / divisor)
  const remainder = number % divisor

  return [quotient, remainder]
}

export const zeroPad = (integer, amount) => {
  let integerString = integer.toString()

  while (integerString.length < amount) {
    integerString = `0${integerString}`
  }

  return integerString
}

export const minutesAndSecondsString = (hours, minutes, seconds) => {
  let str = `${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`

  if (hours > 0) str = `${hours}:${str}`

  return str
}

export function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export class PivotalExecutioner {
  constructor({ waitFor, dontWaitLongerThan }) {
    this.waitFor = waitFor || 2_000
    this.activeTimeoutHandler = null
    this.executionDelayedSince = null
    this.dontWaitLongerThan = dontWaitLongerThan || 10_000
  }

  cancel() {
    this.executionDelayedSince = null
    this.ensureTimeoutCancelled()
  }

  ensureTimeoutCancelled() {
    if (this.activeTimeoutHandler) {
      clearTimeout(this.activeTimeoutHandler)
      this.activeTimeoutHandler = null
    }
  }

  execute(func, { waitFor } = {}) {
    const now = new Date()
    if (!this.executionDelayedSince) this.executionDelayedSince = now

    this.ensureTimeoutCancelled()

    const executeFunction = () => {
      this.executionDelayedSince = null
      func()
    }

    const msSinceExecutionDelayed = now - this.executionDelayedSince
    const delayInMs = Math.min(
      waitFor || this.waitFor,
      Math.max(0, this.dontWaitLongerThan - msSinceExecutionDelayed)
    )

    this.activeTimeoutHandler = setTimeout(executeFunction, delayInMs)
  }
}

export const getStartOfCurrentDay = () => {
  return DateTime.local()
    .plus({ hours: -HOURS_OFFSET })
    .startOf('day')
    .plus({ hours: HOURS_OFFSET })
}

export const updateProjectCache = (cache, data) => {
  if (!data) return console.log('[ERROR] updating project failed (no data)')

  const { data: { updateProject: { project } } } = data

  if (!project) return console.log('[ERROR] updating project failed (no project). handle validation errors.')

  const { projects } = cache.readQuery({ query: FETCH_PROJECTS_QUERY })
  const updatedProjects = [...projects]
  const projectIndex = updatedProjects.findIndex(updatedProject => updatedProject.id === project.id)
  updatedProjects[projectIndex] = { ...project }

  cache.writeQuery({
    query: FETCH_PROJECTS_QUERY,
    data: { projects: updatedProjects },
  })
}

export const buildUpdateProjectParams = (project, newParams) => {
  const params = {
    name: project.name,
    description: project.description,
    notes: project.notes,
    active: project.active,
    streak: project.streak,
    focus: project.focus,
    frequency: project.frequency,
    minutesPerDay: project.minutesPerDay,
    wallpaperImageId: project.wallpaperImageId,
    buttonBackgroundImageId: project.buttonBackgroundImageId,
    colorPalette: project.colorPalette.map((color) => ({ hexCode: color.hexCode })),
    projectType: project.projectType,
    workingHours: {
      from: project.workingHours.from,
      to: project.workingHours.to,
    },
    daysOff: project.daysOff === null ? null : {
      weekly: {
        selectedWeekdays: {
          monday: project.daysOff.weekly.selectedWeekdays.monday,
          tuesday: project.daysOff.weekly.selectedWeekdays.tuesday,
          wednesday: project.daysOff.weekly.selectedWeekdays.wednesday,
          thursday: project.daysOff.weekly.selectedWeekdays.thursday,
          friday: project.daysOff.weekly.selectedWeekdays.friday,
          saturday: project.daysOff.weekly.selectedWeekdays.saturday,
          sunday: project.daysOff.weekly.selectedWeekdays.sunday,
        },
      },
    },
    lowestVisibilityUntil: project.lowestVisibilityUntil,
    countsTowardsGlobalMinutesPerDay: project.countsTowardsGlobalMinutesPerDay,
  }

  return {
    id: project.id,
    params: { ...params, ...newParams },
  }
}

export function useSyncNotifier(setIsSyncing, setSyncError, isSyncing, syncError) {
  useEffect(() => {
    setIsSyncing(isSyncing)
  }, [isSyncing, setIsSyncing])

  useEffect(() => {
    setSyncError(syncError)
  }, [syncError, setSyncError])
}

export const sessionDuration = session => {
  const startedAt = DateTime.fromISO(session.startedAt)
  const endedAt = DateTime.fromISO(session.endedAt)

  return endedAt.diff(startedAt)
}
