export type Id = string;

export interface Color {
  hexCode: string;
}

export interface Project {
  id: Id;
  name: string;
  description: string;
  notes: string;
  frequency: number | null;
  minutesPerDay: number | null;
  active: boolean;
  streak: boolean;
  focus: boolean;
  wallpaperImageId: Id | null;
  buttonBackgroundImageId: Id | null;
  colorPalette: Color[];
  projectType: ProjectType;
  daysOff?: RepeatCycle;
  workingHours: TimeWindow;
  lowestVisibilityUntil: string | null;
  countsTowardsGlobalMinutesPerDay: boolean;
}

export enum ProjectType {
  Work = 'WORK',
  TimeOff = 'TIME_OFF',
  Care = 'CARE',
}

export interface Streak {
  length: number;
  missed: number;
  rate?: number;
  nextSessionDueInDays: number;
  numberOfDaysSinceLastSession: number | null;
}

export interface ProjectSelectorProject extends Project {
  activityToday: number;
  currentStreak: Streak | null;
  neglectionFactor: number | null;
  isTimeOff: boolean;
  dueToday: boolean;
  isSnoozed: boolean;
}

export interface StreakableProject extends Project {
  frequency: number;
}

export interface Session {
  id: Id;
  startedAt: string;
  endedAt: string;
  projectId: Id | null;
  summary: string;
  notes: string;
}

export interface AppState {
  startedAt: string | null;
  projectId: Id | null;
  summary: string;
  notes: string;
  countdownStartedAt: string | null;
  countdownLength: number | null;
}

export interface Settings {
  minutesPerDay: number | null;
  daysOff?: RepeatCycle;
  workingHours: TimeWindow;
  timeOfDaybreak: string;
}

export interface RepeatCycle {
  weekly?: WeeklyRepeatCycle;
}

export interface WeeklyRepeatCycle {
  selectedWeekdays: WeekdaySelection;
}

export interface WeekdaySelection {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface TimeWindow {
  from: string;
  to: string;
}

export enum ImageDisplayOption {
  CoverAvailableSpace = 'COVER_AVAILABLE_SPACE',
  CoverAvailableSpaceBlurred = 'COVER_AVAILABLE_SPACE_BLURRED',
  DownscaleToFitWithBlurredBackground = 'DOWNSCALE_TO_FIT_WITH_BLURRED_BACKGROUND',
  DownscaleToFitWithBlurredBackgroundWithMargin = 'DOWNSCALE_TO_FIT_WITH_BLURRED_BACKGROUND_WITH_MARGIN',
  DownscaleToFitWithBackgroundColor = 'DOWNSCALE_TO_FIT_WITH_BACKGROUND_COLOR',
  DownscaleToFitWithBackgroundColorWithMargin = 'DOWNSCALE_TO_FIT_WITH_BACKGROUND_COLOR_WITH_MARGIN',
}

export enum ImageSize {
  Original = 'original',
  Xs = 'xs',
}

export interface Image {
  id: Id;
  url: string;
  urlXs: string;
  display: ImageDisplayOption;
  backgroundColor?: string;
  tags: string;
}

export interface ImagesIndex {
  [id: Id] : Image;
}

export interface IdFieldOnly {
  id: Id;
}

export interface ValidationError {
  field: string;
  messages: string[];
}

export interface GetImagesResult {
  getImages: Image[];
}

export interface GetProjectsResult {
  projects: Project[];
}

export interface GetImageIdsResult {
  getImages: IdFieldOnly[];
}

export interface CreateImageResult {
  createImageAndLinkToProject: {
    image?: Image;
    errors?: ValidationError[];
  }
}

export interface UpdateSettingsResult {
  updateSettings: {
    settings?: Settings;
    errors?: ValidationError[];
  }
}

export interface GetAppStateResult {
  appState: AppState;
  getSettings: Settings;
}

export interface UpdateImageResult {
  updateImage: {
    image?: Image;
    errors?: ValidationError[];
  }
}

export interface DeleteImageResult {
  deleteImage: boolean;
}

export interface LinkImageToProjectResult {
  linkImageToProject: boolean;
}

export interface UnlinkImageFromProjectResult {
  unlinkImageFromProject: boolean;
}

export type ModalArguments = string[]
