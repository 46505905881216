import { useRef } from 'react'
import { useMutation } from '@apollo/client'
import { FETCH_PROJECTS_QUERY, ADD_PROJECT_QUERY } from './queries'
import {
  InputGroup,
  Form,
  ActionRow,
} from './form'
import { Button } from './clickables'
import {
  NameInput,
  DescriptionInput,
  FrequencyInput,
} from './project_form'

function NewProjectForm({ closeModal }) {
  const nameInputField = useRef()
  const descriptionInputField = useRef()
  const frequencyInputField = useRef()

  const [createProject] = useMutation(ADD_PROJECT_QUERY, {
    update(cache, { data: { createProject: { project } } }) {
      if (!project) return console.log('Project invalid')

      const { projects } = cache.readQuery({ query: FETCH_PROJECTS_QUERY })

      cache.writeQuery({
        query: FETCH_PROJECTS_QUERY,
        data: { projects: projects.concat([project]) },
      })
    },
  })

  const submitNewProject = event => {
    event.preventDefault()

    const name = nameInputField.current.value.trim()
    const description = descriptionInputField.current.value.trim()
    const frequency = parseInt(frequencyInputField.current.value)

    createProject({
      variables: {
        name,
        description,
        active: true,
        frequency,
        notes: '',
      },
    })

    nameInputField.current.value = ''
    descriptionInputField.current.value = ''
    frequencyInputField.current.value = ''

    closeModal()
  }

  return (
    <Form onSubmit={submitNewProject}>
      <InputGroup>
        <NameInput inputRef={nameInputField} />
      </InputGroup>
      <InputGroup>
        <DescriptionInput inputRef={descriptionInputField} />
      </InputGroup>
      <InputGroup>
        <FrequencyInput inputRef={frequencyInputField} />
      </InputGroup>
      <ActionRow>
        <Button submit>Create new project</Button>
      </ActionRow>
    </Form>
  )
}

export default NewProjectForm
