import classNames from 'classnames'

import './SectionContainer.css'

const SectionContainer = (
  { children, fullWidth, fullHeight, widthByContent }:
  {
    children: React.ReactNode;
    fullWidth?: boolean;
    fullHeight?: boolean;
    widthByContent?: boolean;
  },
) => {
  const className = classNames(
    'SectionContainer',
    {
      'SectionContainer-full-width': fullWidth,
      'SectionContainer-width-by-content': widthByContent,
      'SectionContainer-full-height': fullHeight,
    },
  )

  return (
    <div className={className}>
      {children}
    </div>
  )
}

export default SectionContainer
