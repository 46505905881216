import React, { useState } from 'react'
import { LabeledInputBlock, TextInput } from '../form'
import { Color } from '../types'

function ColorPaletteInput(
  { inputRef, onChange, initialValue: initialColorPalette }:
  { initialValue: Color[]; onChange: (colorPalette: Color[]) => void; inputRef: React.RefObject<HTMLInputElement> },
) {
  const id = 'project-color-palette'
  const [colorPaletteString, setColorPaletteString] = useState(initialColorPalette.map((color) => color.hexCode).join(', '))

  const onColorPaletteChange = (newColorPaletteString: string) => {
    setColorPaletteString(newColorPaletteString)

    const colorPalette = newColorPaletteString
      .split(',')
      .map((potentialColorHex: String) => {
        const potentialColorHexTrimmed = potentialColorHex.trim()

        return potentialColorHexTrimmed.length > 0 ? potentialColorHexTrimmed : null
      })
      .filter((colorHexOrNull: String | null) => colorHexOrNull !== null)
      .map((colorHex) => ({ hexCode: colorHex! }))

    onChange(colorPalette)
  }

  return (
    <LabeledInputBlock
      id={id}
      text="Color palette"
      description="Comma seperated hex-color-codes (i.e. #ffffff, #123456)"
      optional
    >
      <TextInput
        inputRef={inputRef}
        onChange={onColorPaletteChange}
        value={colorPaletteString}
        id={id}
      />
    </LabeledInputBlock>
  )
}

export default ColorPaletteInput
