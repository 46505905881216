import React, { useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  InputGroup,
  Form,
  ActionRow,
  LabeledInputBlock,
  EmailInput,
  PasswordInput,
  Errors,
} from './form'
import { REGISTER_USER_MUTATION } from './queries'
import { Button } from './clickables'

import './NewUserForm.css'

const emailId = 'new-user-email'
const passwordId = 'new-user-password'
const passwordConfirmationId = 'new-user-password-confirmation'

function NewUserForm({ onRegistered }) {
  const emailInput = useRef()
  const passwordInput = useRef()
  const passwordConfirmationInput = useRef()
  const [errors, setErrors] = useState([])
  const [registerUser, { loading: submittingUser }] = useMutation(REGISTER_USER_MUTATION, {
    onCompleted: ({ registerUser: { errors, user } }) => {
      if (errors && errors.length > 0) {
        setErrors(errors)
      } else {
        onRegistered()
      }
    },
  })
  const handleSubmit = event => {
    event.preventDefault()
    setErrors([])

    const email = emailInput.current.value
    const password = passwordInput.current.value
    const passwordConfirmation = passwordConfirmationInput.current.value

    if (password !== passwordConfirmation) {
      setErrors(['Passwords don\'t match'])
      return
    }

    registerUser({
      variables: {
        email,
        password,
      },
    })
  }

  return (
    <div className="NewUserForm-container">
      <Form onSubmit={handleSubmit}>
        <Errors messages={errors} />
        <InputGroup>
          <LabeledInputBlock
            id={emailId}
            text="Email"
          >
            <EmailInput
              inputRef={emailInput}
              id={emailId}
              required
            />
          </LabeledInputBlock>
        </InputGroup>
        <InputGroup>
          <LabeledInputBlock
            id={passwordId}
            text="Password"
          >
            <PasswordInput
              inputRef={passwordInput}
              id={passwordId}
              required
              newPassword
            />
          </LabeledInputBlock>
        </InputGroup>
        <InputGroup>
          <LabeledInputBlock
            id={passwordConfirmationId}
            text="Repeat password"
          >
            <PasswordInput
              inputRef={passwordConfirmationInput}
              id={passwordConfirmationId}
              required
              newPassword
            />
          </LabeledInputBlock>
        </InputGroup>
        <ActionRow>
          <Button disabled={submittingUser} submit>Sign up</Button>
        </ActionRow>
      </Form>
    </div>
  )
}

export default NewUserForm
