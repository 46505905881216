import { useRef } from 'react'
import {
  ImageDisplayOption,
  Id,
  GetImagesResult,
  CreateImageResult,
  GetImageIdsResult,
} from './types'
import { useMutation } from '@apollo/client'
import { FaFileImage } from 'react-icons/fa6'
import { CREATE_IMAGE_MUTATION, FETCH_IMAGES_QUERY, FETCH_IMAGE_IDS_FOR_PROJECT_QUERY } from './queries'
import { FileInput } from './form'

function NewImageForm({ projectId }: { projectId: Id; }) {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [createImage, { loading: creatingImage }] = useMutation<CreateImageResult>(CREATE_IMAGE_MUTATION, {
    update(cache, { data: mutationData }) {
      if (!mutationData) return

      const image = mutationData.createImageAndLinkToProject.image

      if (!image) return

      const cachedFetchedImages = cache.readQuery<GetImagesResult>({ query: FETCH_IMAGES_QUERY })
      if (cachedFetchedImages) {
        cache.writeQuery<GetImagesResult>({
          query: FETCH_IMAGES_QUERY,
          data: { getImages: [...cachedFetchedImages.getImages, image] },
        })
      }

      const cachedFetchedImageIds = cache.readQuery<GetImagesResult>({ query: FETCH_IMAGE_IDS_FOR_PROJECT_QUERY, variables: { projectId } })
      if (cachedFetchedImageIds) {
        cache.writeQuery<GetImageIdsResult>({
          query: FETCH_IMAGE_IDS_FOR_PROJECT_QUERY,
          variables: { projectId },
          data: { getImages: [...cachedFetchedImageIds.getImages, image] },
        })
      }
    },
  })

  const handleFileChange = (files: FileList | null) => {
    if (!files) return

    const file = files[0]
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function() {
      createImage({
        variables: {
          projectId,
          imageBase64: reader.result ? reader.result.toString().split(',')[1] : null,
          fileName: file.name,
          fileType: file.type,
          display: ImageDisplayOption.DownscaleToFitWithBlurredBackground,
          backgroundColor: '#060606',
          tags: '',
        },
      })
    }
    reader.onerror = function(error) {
      console.error('Error processing selected file', error)
    }
  }

  return (
    <>
      <FileInput
        inputRef={fileInputRef}
        onChange={handleFileChange}
        id="image-select"
        disabled={creatingImage}
        label={<><FaFileImage /> Upload New Image</>}
        accept="image/png,image/jpeg,image/webp"
      />
    </>
  )
}

export default NewImageForm
