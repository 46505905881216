import { Image as ImageType, ImageDisplayOption, ImageSize } from './types'
import classNames from 'classnames'
import './Image.css'

// Cover the available space entirely with the given image url.
function Wallpaper({ wallpaperUrl }: { wallpaperUrl: string }) {
  return (
    <img
      src={wallpaperUrl}
      alt=""
      className="BackdropWallpaper-img"
    />
  )
}

// Display the given picture in its entirety, without upscaling. Fill the rest
// of the space with a blurred version of that image or a color.
function PictureWallpaper(
  { pictureUrl, leaveMargin, backgroundColor, backgroundOnly }:
  {
    pictureUrl: string;
    leaveMargin: boolean;
    backgroundColor?: string;
    backgroundOnly: boolean;
  },
) {
  return (
    <>
      <div
        className={
          classNames(
            'BackdropPictureWallpaper-background', {
              'BackdropPictureWallpaper-background-blur': !backgroundColor,
            },
          )
        }
        style={{ backgroundColor }}
      >
        {!backgroundColor && <img src={pictureUrl} alt="" />}
      </div>
      {!backgroundOnly && (
        <div className="BackdropPictureWallpaper-picture-container">
          {leaveMargin ? (
            <div className="BackdropPictureWallpaper-picture">
              <img src={pictureUrl} alt="" />
            </div>
          ) : (
            <img src={pictureUrl} alt="" />
          )}
        </div>
      )}
    </>
  )
}

function urlForSize(image: ImageType, size?: ImageSize): string {
  if (size === ImageSize.Xs) {
    return image.urlXs
  }

  return image.url
}

function Image(
  { image, size }:
  { image: ImageType; size?: ImageSize; },
) {
  const url = urlForSize(image, size)

  if (image.display === ImageDisplayOption.CoverAvailableSpace) {
    return (
      <div className="Image">
        <Wallpaper wallpaperUrl={url} />
      </div>
    )
  }

  const leaveMargin = [
    ImageDisplayOption.DownscaleToFitWithBlurredBackgroundWithMargin,
    ImageDisplayOption.DownscaleToFitWithBackgroundColorWithMargin,
  ].includes(image.display)

  const defaultBackgroundColor = '#060606'
  const backgroundColorAsBackground = [
    ImageDisplayOption.DownscaleToFitWithBackgroundColor,
    ImageDisplayOption.DownscaleToFitWithBackgroundColorWithMargin,
  ].includes(image.display)

  return (
    <div className="Image">
      <PictureWallpaper
        pictureUrl={url}
        leaveMargin={leaveMargin}
        backgroundColor={backgroundColorAsBackground ? (image.backgroundColor || defaultBackgroundColor) : undefined}
        backgroundOnly={image.display === ImageDisplayOption.CoverAvailableSpaceBlurred}
      />
    </div>
  )
}

export default Image
