import { Image, Project } from './types'
import ImageEditor from './ImageEditor'

function EditImage({ image, project, closeModal }: { image: Image; project: Project; closeModal: () => void; }) {
  return (
    <ImageEditor image={image} project={project} beforeDelete={closeModal} beforeUnlink={closeModal} />
  )
}

export default EditImage
