import React from 'react'
import classNames from 'classnames'
import HtmlInput from './HtmlInput'

function TextInput(
  { inputRef, value, onChange, id, required, outOfSync, onKeyDown }:
  {
    inputRef?: React.RefObject<HTMLInputElement>;
    value?: string;
    onChange?: (newValue: string) => void;
    onKeyDown?: () => void;
    id?: string;
    required?: boolean;
    outOfSync?: boolean;
  },
) {
  return (
    <HtmlInput
      type="text"
      inputRef={inputRef}
      value={value}
      onChange={(event) => onChange && onChange(event.currentTarget.value)}
      onKeyDown={onKeyDown}
      id={id}
      required={required}
      className={classNames({
        'input-out-of-sync': outOfSync,
      })}
    />
  )
}

export default TextInput
