import React from 'react'

interface SelectInputOption {
  value: string;
  label: string;
}

type SelectInputOptions = SelectInputOption[]

function SelectInput(
  { elementRef, value, onChange, id, required, options }:
  {
    elementRef?: React.RefObject<HTMLSelectElement>;
    value: string;
    onChange: (newValue: string) => void;
    id?: string;
    required?: boolean;
    options: SelectInputOptions;
  },
) {
  return (
    <select
      value={value}
      onChange={(event) => onChange(event.currentTarget.value)}
      ref={elementRef}
      id={id}
      required={required}
    >
      {options.map(({ value, label }) => (
        <option
          key={value}
          value={value}
        >
          {label}
        </option>
      ))}
    </select>
  )
}

export default SelectInput
