import React from 'react'
import styles from './InputGroup.module.scss'

function InputGroup(
  { children, validationErrors }:
  { children: React.ReactNode; validationErrors?: string[] },
) {
  return (
    <div className={styles.container}>
      {children}
      {(validationErrors && validationErrors.length > 0) && (
        <ul className={styles.validationErrorsContainer}>
          {validationErrors.map((validationError) => (
            <li key={validationError}>
              {validationError}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default InputGroup
